import type { HttpError } from "@/services/httpClient";
import { createHttpError, createLocalizedError, LocalizedError } from "@/services/custom-errors";
import { useAuthenticatedUserStore } from "@/services/store";

const httpErrorTexts = new Map<number, string>([
  [400, "Skickad information saknas eller är ogiltig"],
  [404, "Vi kan inte hitta det du söker"],
  [409, "Konflikt med det nuvarnade tillståndet"],
  [410, "Det du söker finns inte längre"],
  [500, "Ett fel inträffade"],
  [504, "Systemet svarade inte i tid"],
]);

function handleHttpErrors(error: HttpError) {
  if (error.response?.status) {
    const errorMessage = httpErrorTexts.get(error.response?.status) || null;
    if (errorMessage) {
      return createHttpError(errorMessage, error);
    }
  }
  return null;
}

function handleNetworkError(error: HttpError) {
  if (error.code !== "ERR_NETWORK") {
    return null;
  }
  return createHttpError("Din nätverksanslutning fungerar inte för tillfället.", error);
}

function handleAll(error: Error) {
  const errorMessage = "Något gick fel, var vänlig att ladda om sidan.";

  return createLocalizedError(errorMessage, error);
}

const genericErrorHandlers = [handleNetworkError, handleHttpErrors];

export type ErrorHandler = (error: HttpError, messageParameters: ErrorMessageParameters) => null | LocalizedError;

type ErrorMessageParameters = {
  userSignature: string;
};

function evaluateErrorHandlers(handlers: ErrorHandler[], error: HttpError, messageParams: ErrorMessageParameters) {
  for (const handler of handlers) {
    const result = handler(error, messageParams);
    if (result !== null) {
      return result;
    }
  }
}

export default function resolveHttpErrorHandler(error: HttpError): LocalizedError {
  const store = useAuthenticatedUserStore();

  const requestSpecificHandlers = error?.config?.errorHandlers;
  const httpCodeSpecificErrorHandlers = Array.isArray(requestSpecificHandlers) ? requestSpecificHandlers : [];
  const errorHandlers = [...httpCodeSpecificErrorHandlers, ...genericErrorHandlers];

  const resolvedError = evaluateErrorHandlers(errorHandlers, error, {
    userSignature: store.user.signature,
  });

  return resolvedError ?? handleAll(error);
}
