import type { RouteLocationNormalized, RouteLocationNamedRaw } from "vue-router";
import { parseDate } from "@/helpers/time";

const TIME_REPORT_NAME = "work-order-time-report-create";
const EXPENSE_REPORT_NAME = "work-order-expense-report-create";

export const reportRoutes = [
  {
    path: "time-reports/create",
    name: TIME_REPORT_NAME,
    meta: {
      requiresAuth: true,
      title: "Skapa tidrapport",
    },
    props: (route: RouteLocationNormalized) => ({
      date: typeof route.query.date === "string" ? parseDate(route.query.date) : undefined,
    }),
    component: () => import("@/modules/report/views/TimeReport.vue"),
  },
  {
    path: "expense-report/create",
    name: EXPENSE_REPORT_NAME,
    props: (route: RouteLocationNormalized) => ({
      date: typeof route.query.date === "string" ? parseDate(route.query.date) : undefined,
      timeTypeId: typeof route.query.timeTypeId === "string" ? route.query.timeTypeId : undefined,
    }),
    meta: {
      requiresAuth: true,
      title: "Rapportera omkostnader",
    },
    component: () => import("@/modules/report/views/ExpenseReport.vue"),
  },
];

type ExpenseReportQuery = {
  date?: string;
  timeTypeId?: string;
};
export function getExpenseReportLink(workOrderId: string, timeReport?: ExpenseReportQuery): RouteLocationNamedRaw {
  return {
    name: EXPENSE_REPORT_NAME,
    params: { id: workOrderId },
    query: {
      date: timeReport?.date,
      timeTypeId: timeReport?.timeTypeId,
    },
  };
}

export function getTimeReportLink(workOrderId: string, date?: string): RouteLocationNamedRaw {
  return {
    name: TIME_REPORT_NAME,
    params: { id: workOrderId },
    query: { date },
  };
}
