export function configValue<K extends keyof ApplicationConfiguration>(key: K): ApplicationConfiguration[K] {
  if (import.meta.env.VITE_VUE_APP_SKA_VUE_HAS_RUNTIME_CONFIG === "true") {
    return globalThis.import_meta_env[key];
  } else {
    return import.meta.env["VITE_" + key];
  }
}

export function ezipBaseUrl() {
  return configValue("VUE_APP_ENVIRONMENT") === "prod" ? "https://ezip.ezadmin.se" : "https://ezip.stage.ezadmin.se";
}
