import { useDisplay } from "vuetify";
import { computed } from "vue";

export function useBreakpoint() {
  const display = useDisplay();

  return computed(() => {
    return {
      mobile: display.mobile.value,
    };
  });
}
