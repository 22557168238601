import type { LocationQueryValue } from "vue-router";

/**
 * Parses a string or array of strings and returns a string or undefined.
 * @param {string | (string | null)[]} value - The query parameter value to parse.
 * @returns {string | undefined} We're only interested in the first value we can't handle multiple values hence undefined.
 */
export function parseStringOrUndefined(
  value: LocationQueryValue | LocationQueryValue[] | undefined,
): string | undefined {
  if (value === null || typeof value !== "string") {
    return undefined;
  }

  return value;
}

export function parseLocationQueryValueInt(
  value: LocationQueryValue | LocationQueryValue[] | undefined,
  defaultValue: number,
) {
  if (value == null || typeof value !== "string") {
    return defaultValue;
  }

  const parsingResult = parseInt(value, 10);
  if (!isNaN(parsingResult)) {
    return parsingResult;
  }
  return defaultValue;
}
