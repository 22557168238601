import type { HttpError } from "@/services/httpClient";

export class LocalizedError extends Error {
  options: { cause: unknown };
  localizedMessage: string;

  constructor(localizedMessage: string, options?: { cause: unknown }) {
    // TODO: VUE 3 we should pass the cause when running on ES2022
    super(localizedMessage);
    this.name = this.constructor.name;

    this.localizedMessage = localizedMessage;

    const cause = options?.cause;
    this.options = { cause };

    if (cause instanceof Error) {
      this.stack = cause.stack;
    }
  }
}

export class HttpLocalizedError extends LocalizedError {
  httpStatusCode: number;
  constructor(localizedMessage: string, options: { cause: HttpError; httpStatusCode: number }) {
    super(localizedMessage, options);
    this.name = this.constructor.name;
    this.stack = options.cause.stack;

    this.httpStatusCode = options.httpStatusCode;
  }
}

export function createHttpError(message: string, cause: HttpError) {
  if (cause.response?.status) {
    return new HttpLocalizedError(message, { cause: cause, httpStatusCode: cause.response.status });
  }
  return new LocalizedError(message, { cause: cause });
}

export function createLocalizedError(message: string, cause?: unknown) {
  return new LocalizedError(message, { cause: cause });
}

export function createUnknownError(cause: unknown) {
  return new LocalizedError(unknownErrorMessage, { cause: cause });
}

export const unknownErrorMessage: string = "Ett okänt fel har inträffat" as const;
