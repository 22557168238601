import {
  addPrefixToKeys,
  filterObjectOnKeyContaining,
  removeNullValues,
  removePrefixFromObjectKeys,
} from "@/helpers/object-helpers";
import type { LocationQuery, RouteLocationRaw } from "vue-router";
import { SortDirection, WorkOrderSortFields } from "@/core/api/data-contracts";
import type { WorkOrderSearchParameters } from "./types";
import { parseLocationQueryValueInt, parseStringOrUndefined } from "@/core/router/routeParser";

export const defaultParameters = {
  searchTerm: undefined,
  label: undefined,
  showAllWorkOrders: false,
  showClosedWorkOrders: false,
  sortDirection: 1,
  sortField: 0,
  offset: 0,
  limit: 50,
  facet: {},
};

const FACET_PREFIX = "facet-";

export function makeWorkOrderSearchParamsFromRoute(parameters: LocationQuery): WorkOrderSearchParameters {
  const facets = filterObjectOnKeyContaining(parameters, FACET_PREFIX);
  const facetsWithoutPrefix = removePrefixFromObjectKeys(facets, FACET_PREFIX);
  return {
    ...defaultParameters,
    limit: parseLocationQueryValueInt(parameters.limit, defaultParameters.limit),
    offset: parseLocationQueryValueInt(parameters.offset, defaultParameters.offset),
    sortDirection: parseLocationQueryValueInt(parameters.sortDirection, defaultParameters.sortDirection),
    sortField: parseLocationQueryValueInt(parameters.sortField, defaultParameters.sortField),
    showAllWorkOrders: parameters.showAllWorkOrders === "true",
    showClosedWorkOrders: parameters.showClosedWorkOrders === "true",
    facet: facetsWithoutPrefix,
    searchTerm: parseStringOrUndefined(parameters.searchTerm),
    label: parseStringOrUndefined(parameters.label),
  };
}

type WorkOrderSearch = {
  searchTerm?: string | null;
  label?: string | null;
  sortDirection?: SortDirection;
  sortField?: WorkOrderSortFields;
  limit?: number;
  offset?: number;
  showAllWorkOrders?: boolean;
  showClosedWorkOrders?: boolean;
  facet?: object;
};

export function makeRouterQueryFromWorkOrderSearch(searchObject: WorkOrderSearch) {
  const facetsWithPrefix = addPrefixToKeys(searchObject.facet ?? {}, FACET_PREFIX);
  const queryParameters = {
    searchTerm: searchObject.searchTerm,
    label: searchObject.label,
    sortDirection: searchObject.sortDirection?.toString(),
    sortField: searchObject.sortField?.toString(),
    limit: searchObject.limit?.toString(),
    offset: searchObject.offset?.toString(),
    showAllWorkOrders: searchObject.showAllWorkOrders?.toString(),
    showClosedWorkOrders: searchObject.showClosedWorkOrders?.toString(),
    ...facetsWithPrefix,
  };
  return removeNullValues(queryParameters);
}

export function generateWorkOrderLabelRoute(label: string): RouteLocationRaw {
  const searchObject = {
    ...defaultParameters,
    label: label,
  };
  const query = makeRouterQueryFromWorkOrderSearch(searchObject);
  return {
    name: "work-orders",
    query,
  };
}
