import type { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import type { App, Plugin } from "vue";

type GlobalErrorHandlerPluginOptions = {
  applicationInsights: ApplicationInsights;
};

export function createGlobalErrorHandlerPlugin(): Plugin<GlobalErrorHandlerPluginOptions> {
  return {
    install(app: App, options: GlobalErrorHandlerPluginOptions) {
      app.config.errorHandler = (error: unknown) => {
        if (!options) {
          return;
        }
        if (error instanceof Error) {
          options.applicationInsights.trackException({ exception: error, severityLevel: SeverityLevel.Error });
        }
      };
    },
  };
}
