import { jwtDecode } from "jwt-decode";
import type { JwtPayload } from "jwt-decode";

type customJwtPayload = JwtPayload & {
  "ez.company": string;
  "ez.usercode": string;
  role: string[];
};

export function decodeJwt(token: string) {
  return jwtDecode<customJwtPayload>(token);
}
