<script setup lang="ts">
import { useAuthenticatedUserStore, useCompanySelector } from "@/services/store";
import { useSelectedTheme } from "./UseTheme";
import { useApplicationInsights } from "@/services/application-insights/use-application-insights";
import { useUserMeQuery } from "@/modules/authentication/queries";

const store = useAuthenticatedUserStore();
const companySelector = useCompanySelector();

const { setMode, isDarkMode } = useSelectedTheme();
const applicationInsights = useApplicationInsights();

const { data: userMeData } = useUserMeQuery();

const changeTheme = (value: boolean | null) => {
  const updatedDarkMode = value ?? false;
  setMode(updatedDarkMode ? "dark" : "light");
  applicationInsights.trackEvent({
    name: "Theme Color Changed",
    properties: { eventCategory: "Application", isDarkMode: updatedDarkMode },
  });
};

async function changeCompanyId(companyId: string | null) {
  await companySelector.selectCompany(companyId);
}
</script>

<template>
  <VMenu>
    <template #activator="{ props: activatorProps }">
      <VAvatar v-bind="activatorProps" color="white">
        <VIcon>mdi-account-outline</VIcon>
      </VAvatar>
    </template>

    <VList>
      <VListItem>
        <VSwitch :model-value="isDarkMode" label="Dark mode" color="primary" @update:model-value="changeTheme"
      /></VListItem>

      <template v-if="(userMeData?.companies.length ?? 0) > 1">
        <VDivider></VDivider>
        <VListSubheader>Byt företag</VListSubheader>
        <VList v-for="company in userMeData?.companies || []" :key="company.id">
          <VListItem
            :class="{ active: store.selectedCompanyId === company.id }"
            @click="() => changeCompanyId(company.id)"
          >
            {{ company.name }}<VIcon v-if="store.selectedCompanyId === company.id" class="iconStyle">mdi-check</VIcon>
          </VListItem>
        </VList>
      </template>
      <VDivider></VDivider>
      <VListItem link to="/signout">
        <template #prepend>
          <VIcon>mdi-logout</VIcon>
        </template>
        Logga ut
      </VListItem>
    </VList>
  </VMenu>
</template>

<style scoped>
.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.iconStyle {
  margin: 0 0 4px 8px;
}
</style>
