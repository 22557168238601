import oidcManager, { getOrSignInUser } from "@/services/oidcManager";
import { getApplicationVersion } from "@/services/api";
import { configValue } from "@/services/config";
import { intercomBoot, intercomShutdown } from "@/services/intercom";
import { decodeJwt } from "@/services/jwt";
import { applicationInsightsInstance } from "@/services/application-insights/application-insights";
import { trackUnknownError } from "@/services/application-insights/track-unknown-error";
import type { ToastArgs } from "@/components/Toaster/types";
import { defineStore } from "pinia";
import { User } from "oidc-client-ts";

type OidcUserProfile = {
  name: string;
  sub: string;
};

type OidcUser = {
  profile: OidcUserProfile;
};

/* TODO: The user profile have multiple problems that needs to fixed
  - We're bleeding the authentication method making it impossible to change how we authenticate
  - We're mixing authenticated profiles vs non authenticated profiles make it hard to type
 */
export type UserProfile = {
  companyId: string;
  isInitialized: boolean;
  isAuthenticated: boolean;
  signature: string;
  oidcUser: OidcUser | null;
  roles: string[];
};

export type UserRole =
  | "se.eaztimate.hubb"
  | "se.eaztimate.hubb.arbetsledare"
  | "se.eaztimate.hubb.utforare"
  | "se.eaztimate.hubb.beta"
  | "se.eaztimate.hubb.dev";

export type StoreState = {
  user: UserProfile;
  application: {
    currentVersion?: string;
    newVersion: null | string;
    hasNewVersion: boolean;
  };
  selectedCompanyId: string | null; // TODO: not sure about the name
};

const roles = {
  foreman: "se.eaztimate.hubb.arbetsledare",
  technician: "se.eaztimate.hubb.utforare",
  beta: "se.eaztimate.hubb.beta",
  developer: "se.eaztimate.hubb.dev",
} as const;

type MainRoles = "foreman" | "worker" | "anonymous";

function initialUser(): UserProfile {
  return {
    companyId: "",
    signature: "",
    isInitialized: false,
    isAuthenticated: false,
    oidcUser: null,
    roles: [],
  };
}

export const useAuthenticatedUserStore = defineStore("authenticatedUser", {
  state: (): StoreState => ({
    user: initialUser(),
    application: {
      currentVersion: configValue("VUE_APP_APPLICATION_VERSION"),
      newVersion: null,
      hasNewVersion: false,
    },
    selectedCompanyId: getSelectedCompany(),
  }),
  getters: {
    isForeman: (state: StoreState): boolean => isInRole(state, roles.foreman),
    isTechnician: (state: StoreState): boolean => isInRole(state, roles.technician),
    isBetaGroup: (state: StoreState): boolean => isInRole(state, roles.beta),
    isDeveloper: (state: StoreState): boolean => isInRole(state, roles.developer),
    hasAccessToWorkOrderCreate(): boolean {
      return this.isForeman;
    },
    hasAccessToWorkOrderRiskRead(): boolean {
      return this.isForeman || this.isTechnician;
    },
    canEditLabels(): boolean {
      return this.isForeman;
    },
    hasAccessToWorkOrderCostRead(): boolean {
      return this.isForeman;
    },
    hasAccessToWorkOrderInvoiceRead(): boolean {
      return this.isForeman;
    },
    hasAccessToSelfInspections(): boolean {
      return this.isForeman || this.isTechnician;
    },
    mainRole(): MainRoles {
      if (this.isForeman) {
        return "foreman";
      } else if (this.isTechnician) {
        return "worker";
      } else {
        return "anonymous";
      }
    },
    companyId: (state: StoreState): string | null => {
      if (state.user) {
        return state.user.companyId;
      }
      return null;
    },
    activeCompanyId: (state: StoreState): string | null => {
      return state.selectedCompanyId ?? state.user.companyId;
    },
    userName(): string | null {
      const user = this.user;
      if (user) {
        return user.oidcUser?.profile?.name ?? null;
      } else {
        return null;
      }
    },
  },
  actions: {
    setUser(user: UserProfile) {
      this.user = user;
    },
    userClear() {
      this.user = {
        ...initialUser(),
        isInitialized: true,
      };
    },
    applicationVersion({ version }: { version: string }) {
      this.application.hasNewVersion = true;
      this.application.newVersion = version;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toastAdd(toast: ToastArgs) {
      // NOP: This event is being handled by useToaster()
    },
    async updateVersion({ source }: { source: "user-init" | "user-update" }) {
      if (this.application.hasNewVersion) {
        // No need to check if we have detected a new version already
        return;
      }

      try {
        const { data } = await getApplicationVersion();
        if (data.version !== this.application.currentVersion) {
          this.applicationVersion({ version: data.version });
          applicationInsightsInstance().trackEvent({
            name: "Application Version Mismatch",
            properties: {
              eventCategory: "Application",
              source: source,
              currentVersion: this.application.currentVersion,
              latestVersion: data.version,
            },
          });
        }
      } catch {
        // Version tracking should not affect the login
      }
    },
    async userLogin(path: string) {
      // We're leaving the site now, so it's better to flush AI before.
      applicationInsightsInstance().flush();
      await oidcManager.signinRedirect({ state: path });
    },
    async userLogout() {
      intercomShutdown();
      // We're leaving the site now, so it's better to flush AI before.
      applicationInsightsInstance().flush();
      await oidcManager.signoutRedirect();
    },
    async updateUser(userData: AuthenticatedUser | null, options: { source: "user-init" | "user-update" }) {
      if (userData === null) {
        this.userClear();
        return;
      }
      this.setUser({
        isInitialized: true,
        isAuthenticated: true,
        oidcUser: {
          profile: {
            name: userData.email,
            sub: userData.id,
          },
        },
        roles: userData.roles,
        companyId: userData.companyId,
        signature: userData.signature,
      });

      if (options.source === "user-init") {
        intercomBoot({
          user_id: userData.id,
          email: userData.email,
          mainRole: this.mainRole,
        });
      }

      await this.updateVersion(options);
    },
    async userUpdateJwtUser(jwtUser: User) {
      const userData = extractUserData(jwtUser);

      await this.updateUser(userData, { source: "user-update" });
    },
    async userInitJwtUser(jwtUser: User) {
      const userData = extractUserData(jwtUser);

      await this.updateUser(userData, { source: "user-init" });
    },
    async userInit() {
      // userInit is called on every route to avoid looking up the user from the store we can return fast
      //   - logout will be handled either manually or by the session monitor
      //   - tokens will be handled by the signinSilent
      // if we start to see failures with expired access tokens we could consider:
      //   - getting the oidc token before sending ajax requests
      //   - removing this early return to make the state more similar
      if (this.user.isInitialized) {
        return;
      }

      try {
        const user = await getCurrentUser();
        await this.updateUser(user, { source: "user-init" });
      } catch (e) {
        trackUnknownError(applicationInsightsInstance(), e);
        await this.userLogout();
      }
    },
  },
});

function isInRole(state: StoreState, role: UserRole) {
  return state.user.roles.includes(role);
}

async function getCurrentUser() {
  try {
    const user = await getOrSignInUser();
    if (user === null) {
      return null;
    }
    return extractUserData(user);
  } catch {
    // Get user might throw if authentication is needed
    // In this case it's fine if the user is not authenticated
    return null;
  }
}

function extractUserData(user: User): AuthenticatedUser | null {
  if (user.expired) {
    return null;
  }
  const userEmail = user.profile.name;
  if (typeof userEmail !== "string") {
    return null;
  }

  const decodedToken = decodeJwt(user.access_token);

  return {
    id: user.profile.sub,
    email: userEmail,
    roles: decodedToken.role,
    companyId: decodedToken["ez.company"],
    signature: decodedToken["ez.usercode"],
  };
}

type AuthenticatedUser = {
  id: string;
  email: string;
  roles: string[];
  companyId: string;
  signature: string;
};

export function useCompanySelector() {
  const store = useAuthenticatedUserStore();

  const selectCompany = async (companyId: string | null) => {
    store.selectedCompanyId = companyId;
    if (companyId !== null) {
      sessionStorage.setItem(CompanyIdLocalStorageKey, companyId);
    } else {
      sessionStorage.removeItem(CompanyIdLocalStorageKey);
    }
    window.location.href = "/";
  };

  return { selectCompany };
}
const CompanyIdLocalStorageKey = "company-id";

export function getSelectedCompany() {
  return sessionStorage.getItem(CompanyIdLocalStorageKey);
}

export function clearSelectedCompany() {
  sessionStorage.removeItem(CompanyIdLocalStorageKey);
}
