import { configValue } from "@/services/config";
import { useAuthenticatedUserStore } from "@/services/store";
import { ref } from "vue";

const COMPANIES = new Map<string, string>([
  ["32", "Måleritjänst i Syd AB"],
  ["66", "Ocab Gästrikland AB"],
  ["65", "Ocab i Dalarna AB"],
  ["21", "OCAB i Jönköpings- och Skaraborgs län AB"],
  ["23", "Ocab i Linköping AB"],
  ["70", "Ocab i Närke AB"],
  ["20", "Ocab Öst AB"],
  ["41", "Ocab i Ö-vik AB"],
  ["10", "Ocab i Stockholm AB"],
  ["50", "Ocab i Västerbotten AB"],
  ["11", "Ocab i Västmanland AB"],
  ["60", "Ocab Mitt AB"],
  ["51", "Ocab Norr AB"],
  ["40", "Ocab Södra Norrland AB"],
  ["30", "Ocab Syd AB"],
  ["35", "Ocab Sydost AB"],
  ["22", "Ocab Väst AB"],
  ["24", "Planea Öst AB"],
  ["15", "Stahrebolaget AB"],
]);

function getApplicationId() {
  return configValue("VUE_APP_INTERCOM_APP_ID");
}

function intercomInstance(): Intercom_.IntercomStatic | null {
  return getApplicationId() ? globalThis.Intercom : null;
}

const messageCounter = ref(0);

export function useIntercomMessageCounter() {
  return { messageCounter };
}

export function intercomUpdate() {
  intercomInstance()?.("update");
}

type IntercomBoot = {
  user_id: string;
  email: string;
  mainRole: string;
};

export function intercomBoot({ user_id, email, mainRole }: IntercomBoot) {
  const store = useAuthenticatedUserStore();

  const applicationId = getApplicationId();
  const settings: Intercom_.IntercomSettings = {
    app_id: applicationId,
    email: email,
    user_id: user_id,
    userProfession: mainRole,
    userFeatureSet: store.isBetaGroup ? "beta" : "normal",
    company: {
      id: store.user.companyId,
      name: COMPANIES.get(store.user.companyId) || "",
    },
    hide_default_launcher: true,
  };

  intercomInstance()?.("boot", settings);

  // We'll have to wait until Intercom is booted until we attach the listener
  // There's no promise or callback that we can rely on
  setTimeout(() => {
    intercomOnUnreadCountChange((count) => (messageCounter.value = count));
  }, 2000);
}

export function intercomTrackEvent(eventName: string, metaData?: object): void {
  intercomInstance()?.("trackEvent", eventName, metaData ?? {});
}

export function intercomShutdown() {
  intercomInstance()?.("shutdown");
}

export function intercomShow() {
  intercomInstance()?.("show");
}

export function intercomHide() {
  intercomInstance()?.("hide");
}

function intercomOnUnreadCountChange(onUnreadCountChange: (count: number) => void) {
  intercomInstance()?.("onUnreadCountChange", onUnreadCountChange);
}

export function intercomShowArticle(article: ArticleId) {
  const articleId = articles[article];
  intercomInstance()?.("showArticle", articleId);
}

const articles = {
  "time-report": 6976615,
  "comments-mentions": 6973498,
  "work-order-files": 6976549,
  "work-order-states": 8974724,
  "self-inspections": 9018905,
  "project-subtasks": 9296117,
  "zendesk-information": 9329910,
} as const;

export type ArticleId = keyof typeof articles;
