import { configValue } from "@/services/config";
import { compareByLocal } from "@/helpers/array-helpers";
import { makeRouterQueryFromWorkOrderSearch } from "@/modules/workorder-list/workOrderSearchAdapters";
import { WorkOrderState } from "@/core/api/data-contracts";
import type { RouteLocationNamedRaw } from "vue-router";

const customerJourneyUrl = configValue("VUE_APP_CUSTOMER_JOURNEY_URL");

export const navLinks: NavLink[] = [
  {
    title: "Hem",
    to: "home",
    icon: "mdi-home",
  },
  {
    title: "Arbetsordrar",
    to: "work-orders",
    icon: "mdi-clipboard-text",
    children: [
      {
        title: "Mina pågående",
        to: {
          name: "work-orders",
          query: makeRouterQueryFromWorkOrderSearch({
            facet: {
              state: [WorkOrderState.InProgress.toString()],
            },
          }),
        },
      },
      {
        title: "Uppnått slutdatum",
        to: {
          name: "work-orders",
          query: makeRouterQueryFromWorkOrderSearch({
            facet: {
              state: [WorkOrderState.Registered.toString(), WorkOrderState.InProgress.toString()],
              planning: "ReachedPlannedEndDate",
            },
          }),
        },
      },
      {
        title: "Uppnått förväntat antal timmar",
        to: {
          name: "work-orders",
          query: makeRouterQueryFromWorkOrderSearch({
            facet: {
              state: [WorkOrderState.Registered.toString(), WorkOrderState.InProgress.toString()],
              planning: "ReachedExpectedHoursReported",
            },
          }),
        },
      },
      {
        title: "Med ej återlämnade verktyg",
        to: {
          name: "work-orders",
          query: makeRouterQueryFromWorkOrderSearch({
            facet: {
              state: [
                WorkOrderState.WaitingForVerification.toString(),
                WorkOrderState.WorkDone.toString(),
                WorkOrderState.ReadyForInvoicing.toString(),
                WorkOrderState.Invoiced.toString(),
                WorkOrderState.Finished.toString(),
              ],
              tools: "Rented",
            },
          }),
        },
      },
    ],
  },
  {
    title: "Agenda",
    to: "agenda",
    icon: "mdi-clipboard-list",
  },
  {
    title: "Tidrapporter",
    to: "staff-overview",
    icon: "mdi-clock",
  },
  {
    title: "Omkostnader",
    to: "expense-overview",
    icon: "mdi-currency-usd",
    foremanOnly: true,
  },
  {
    title: "Kunder",
    to: "customers",
    icon: "mdi-account-box ",
  },
  {
    title: "Projekt",
    to: "projects",
    icon: "mdi-chart-gantt",
    foremanOnly: true,
  },
];

export function resolveMyPagesLinks() {
  const ifsUrl = configValue("VUE_APP_IFS_URL");

  return [
    {
      title: "IFS Lobby",
      href: `${ifsUrl}/main/ifsapplications/web/lobby/6b4b73f9-c6fa-42c3-b17f-78406d420799`,
      icon: "mdi-size-l",
      foremanOnly: true,
    },
    {
      title: "Mina intyg",
      href: "https://ocabakademin.sabacloud.com/Saba/Web_spf/EU5P1PRD041/app/me/microapp/appf85f0a59672d4540aacfb1a85263ca4f",
      icon: "mdi-certificate",
    },
    {
      title: "Rapportera frånvaro/tillägg",
      href: `${ifsUrl}/main/ifsapplications/web/lobby/6d4387cb-355b-4670-a483-8f1930cd2d5a`,
      icon: "mdi-cash",
    },
    {
      title: "Händelserapportering",
      href: "https://apps.powerapps.com/play/e/default-fcf9dac3-40b2-4e67-a5a0-bef3af86d867/a/747b6f11-8810-4fa7-831a-f53205faa4df?tenantId=fcf9dac3-40b2-4e67-a5a0-bef3af86d867&source=portal",
      icon: "mdi-alert-octagram-outline",
    },
  ];
}

export function resolveExternalLinks() {
  return [...mainLinks].sort(compareByLocal((item) => item.title));
}

const mainLinks = [
  {
    title: "Manualer",
    href: "https://support.ezadmin.se/",
    icon: "mdi-book-open",
  },
  {
    title: "Planeringsverktyget",
    href: "https://ezip.ezadmin.se/",
    icon: "mdi-calendar",
  },
  {
    title: "Kundresor",
    href: customerJourneyUrl,
    icon: "mdi-home",
  },
  {
    title: "Skadedokumentation",
    href: "https://ska.ezadmin.se/",
    icon: "mdi-home-flood",
  },
  {
    title: "Fib",
    href: "https://fib.ezadmin.se/",
    icon: "mdi-hvac",
  },
  {
    title: "Statusbesiktning",
    href: "https://status.ezadmin.se/",
    icon: "mdi-city",
  },
];

export type NavLink = {
  title: string;
  to: string;
  icon: string;
  foremanOnly?: boolean;
  children?: ChildLink[];
};

export type ChildLink = {
  title: string;
  to: RouteLocationNamedRaw;
};
