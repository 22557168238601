import { User, UserManager, WebStorageStateStore } from "oidc-client-ts";
import { configValue } from "@/services/config";
import { applicationInsightsInstance } from "@/services/application-insights/application-insights";
import { trackUnknownError } from "@/services/application-insights/track-unknown-error";
import { useAuthenticatedUserStore } from "@/services/store";

const appEndpoint = configValue("VUE_APP_SKA_VUE_EP");
const authEndpoint = configValue("VUE_APP_SKA_AUTH_EP");
const authClient = configValue("VUE_APP_SKA_AUTH_CLIENT_V2");

export function createUserManager(): UserManager {
  return new UserManager({
    authority: authEndpoint ?? "",
    client_id: authClient ?? "",
    redirect_uri: appEndpoint + "/callback",
    response_type: "code",
    scope: "openid profile api1",
    post_logout_redirect_uri: appEndpoint,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    silent_redirect_uri: appEndpoint + "/authentication/silent-login",
    loadUserInfo: true,
    monitorSession: false,
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTimeInSeconds: 60,
  });
}

const oidcManager = createUserManager();

// Try to sign in silent one last time and logout if there are any errors
oidcManager.events.addAccessTokenExpired(async () => {
  const store = useAuthenticatedUserStore();

  let user = null;
  try {
    user = await signInUserSilently();
  } catch (e) {
    trackUnknownError(applicationInsightsInstance(), e);
  }

  if (user === null) {
    await userSignOut();
  } else {
    await store.userUpdateJwtUser(user);
  }
});

// Triggered when the user is logged out in identity server
oidcManager.events.addUserSignedOut(userSignOut);

oidcManager.events.addSilentRenewError(async (e) => {
  if (!e) {
    return;
  }
  switch (e.message) {
    case "login_required":
      await userSignOut();
      return;
    default:
      applicationInsightsInstance().trackException({
        exception: e,
      });
  }
});

function userSignOut() {
  const store = useAuthenticatedUserStore();
  return store.userLogout();
}

export async function requireAuthenticatedUser() {
  try {
    const user = await getOrSignInUser();

    if (user !== null) {
      return user;
    }
  } catch (e) {
    trackUnknownError(applicationInsightsInstance(), e);
  }

  await userSignOut();

  throw new Error("User has been logged out");
}

export async function getOrSignInUser(): Promise<User | null> {
  const getUserResult = await oidcManager.getUser();
  if (getUserResult !== null && !getUserResult.expired) {
    return getUserResult;
  }
  return await signInUserSilently();
}

async function signInUserSilently(): Promise<User | null> {
  const user = await oidcManager.signinSilent();
  if (user !== null) {
    oidcManager.stopSilentRenew();
    oidcManager.startSilentRenew();
  }
  await oidcManager.clearStaleState();

  return user;
}

export default oidcManager;
