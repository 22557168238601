import { queryOptions, useQuery } from "@tanstack/vue-query";
import httpClient from "@/services/httpClient";
import type { UserMeResponse } from "@/core/api/data-contracts";

const userMeQueryOptions = queryOptions({
  queryKey: ["user", "me"],
  queryFn: async () => {
    const { data } = await httpClient.get<UserMeResponse>("/user/me");
    return data;
  },
  staleTime: 1000 * 60 * 30,
});

export function useUserMeQuery() {
  return useQuery(userMeQueryOptions);
}
