<script setup lang="ts">
import oidcManager from "@/services/oidcManager";
import { defaultRootPage } from "@/router";
import FullScreenBlockingPage from "@/components/BlockingPage.vue";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useApplicationInsights } from "@/services/application-insights/use-application-insights";
import { trackUnknownError } from "@/services/application-insights/track-unknown-error";
import { useAuthenticatedUserStore, useCompanySelector } from "@/services/store";

const appInsights = useApplicationInsights();
const router = useRouter();
const store = useAuthenticatedUserStore();
const { selectCompany } = useCompanySelector();
onMounted(async () => {
  try {
    await selectCompany(null);
    // process callback from auth provider
    const user = await oidcManager.signinRedirectCallback();
    await store.userInitJwtUser(user);

    if (store.user.isAuthenticated) {
      // continue to destination, if the user is now authenticated
      // NOTE: Since we're redirecting from / vue router will throw if we're trying to access that page
      const nextTarget = user.state && user.state !== "/" ? user.state : defaultRootPage();

      try {
        await router.push(nextTarget);
        // Return to avoid default case of navigating to Unauthorized
        return;
      } catch (ex: unknown) {
        /*
         * This catch is needed since vue router might throw if there's a navigation guard, see link below.
         * If you're still curious, try removing the try/catch and call this.$router.push("/");
         * https://stackoverflow.com/questions/5515310/is-there-a-standard-function-to-check-for-null-undefined-or-blank-variables-in
         * */
        trackUnknownError(appInsights, ex);
      }
    } else {
      appInsights.trackTrace({
        message: "User is not authenticated after callback from identity server",
        severityLevel: 2,
      });
    }
  } catch (ex: unknown) {
    trackUnknownError(appInsights, ex);
  }
  await router.push({ name: "Unauthorized" });
});
</script>

<template>
  <FullScreenBlockingPage header="Du loggas nu in..." icon="mdi-login" loader />
</template>
