import type { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export function trackUnknownError(applicationInsights: ApplicationInsights, error: unknown) {
  if (error instanceof Error) {
    applicationInsights.trackException({ exception: error, severityLevel: SeverityLevel.Error });
  } else {
    applicationInsights.trackTrace({ message: "Error of unknown type was thrown", severityLevel: SeverityLevel.Error });
  }
}
