import { createApp } from "vue";
import router from "./router";
import { createApplicationInsights } from "@/services/application-insights/application-insights";
import "@fontsource/inter";
import "@mdi/font/css/materialdesignicons.css";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { createGlobalErrorHandlerPlugin } from "@/services/application-insights/global-error-handler-plugin";
import { createPinia } from "pinia";
import { createVuetifyPlugin } from "@/configuration/vuetify";
import App from "@/modules/root/App.vue";
import type { VueQueryPluginOptions } from "@tanstack/vue-query";

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  },
  enableDevtoolsV6Plugin: true,
};

const pinia = createPinia();
const vuetify = createVuetifyPlugin();
const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(vuetify);
app.use(VueQueryPlugin, vueQueryPluginOptions);

const { applicationInsights, applicationInsightsPlugin } = createApplicationInsights(router);
app.use(applicationInsightsPlugin);

if (import.meta.env.PROD) {
  app.use(createGlobalErrorHandlerPlugin(), { applicationInsights });
}

// If we can't preload the resources e.g. a dependency is missing during a deployment it's better to reload the page
window.addEventListener("vite:preloadError", async (event) => {
  applicationInsights.trackTrace({
    message: "Vite Preload Error",
    severityLevel: 2,
    properties: {
      event: event,
    },
  });
  await applicationInsights.flush();
  location.reload();
});

app.mount("#app");
