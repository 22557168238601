//Polyfill. Replace with Array.at(-1) when support is universal
export function getLast<T>(array: T[]): T | null {
  if (Array.isArray(array) && array.length > 0) {
    return array[array.length - 1] ?? null;
  }
  return null;
}

export function groupBy<T, K>(
  toGroup: (T | undefined | null)[],
  keySelector: (element: T) => K,
): { key: K; values: T[] }[] {
  if (!(toGroup instanceof Array)) {
    throw new Error(`can not group ${typeof toGroup}`);
  }

  if (!keySelector) {
    throw new Error("key selector is required");
  }

  if (!(keySelector instanceof Function)) {
    throw new Error("key selector must be a function");
  }

  const grouped = new Map<K, T[]>();

  toGroup.forEach((element) => {
    if (!element) {
      return;
    }

    let key = undefined;

    try {
      key = keySelector(element);

      if (!key) {
        return;
      }
    } catch {
      return;
    }

    if (grouped.has(key)) {
      grouped.get(key)!.push(element);
    } else {
      grouped.set(key, [element]);
    }
  });

  // remove this conversion after moving to VUE 3
  return Array.from(grouped, (x) => ({
    key: x[0],
    values: x[1],
  }));
}

export function removeItem<T>(arr: T[], value: T): T[] {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function compareByLocal<TItem>(selector: (item: TItem) => string): (a: TItem, b: TItem) => number {
  return (a: TItem, b: TItem) => {
    const aValue = selector(a);
    const bValue = selector(b);

    return aValue.localeCompare(bValue);
  };
}
