<script setup lang="ts">
import type { ToastProps } from "./types";

defineProps<{ toast: ToastProps }>();
const emit = defineEmits<{ (e: "dismiss"): void }>();

function close() {
  emit("dismiss");
}
</script>

<template>
  <VAlert elevation="8" :type="toast.intent" :icon="false" @update:model-value="close">
    <template #text>
      {{ toast.message }}
    </template>
    <template #close="">
      <VBtn size="default" icon color="background" @click="close">
        <VIcon>mdi-close</VIcon>
      </VBtn>
    </template>
  </VAlert>
</template>
