import { createRouter, createWebHistory } from "vue-router";
import type { RouteLocationNormalized } from "vue-router";
import type { UserRole } from "@/services/store";
import SilentLogin from "@/modules/authentication/SilentLogin.vue";
import UserAuth from "@/modules/authentication/UserAuth.vue";
import CallbackPage from "@/modules/authentication/CallbackPage.vue";
import UnauthorizedPage from "@/modules/authentication/UnauthorizedPage.vue";
import { intercomUpdate } from "@/services/intercom";
import { makeWorkOrderSearchParamsFromRoute } from "@/modules/workorder-list/workOrderSearchAdapters";
import { createUserManager } from "@/services/oidcManager";
import { parseDate } from "@/helpers/time";
import { applicationInsightsInstance } from "@/services/application-insights/application-insights";
import { configValue } from "@/services/config";
import { reportRoutes } from "./modules/report/views/routes";
import { useAuthenticatedUserStore } from "@/services/store";
import { useCreateCustomerStore } from "@/modules/customer/store";
import { useWorkOrderSearchStore } from "@/modules/workorder-list/workOrderSearchStore";
import { chartDefaultDates, makeProjectSearchParametersFromRoute } from "@/modules/projects/routes";

export function defaultRootPage() {
  return { name: "home" };
}

function makeTitle(pageName?: string) {
  const title = pageName ?? "";
  const prefix =
    { local: "[DEV]", stage: "[STAGE]", test: "[TEST]", prod: "" }[configValue("VUE_APP_ENVIRONMENT")] ?? "";
  const postFix = "Hubb";
  return `${prefix} ${title} - ${postFix}`;
}

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0, left: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      meta: { title: "Hubb", requiresAuth: true },
      component: () => import("@/modules/home/views/HomePage.vue"),
      alias: ["/home"],
    },
    {
      path: "/list",
      redirect: { name: "work-orders" },
    },
    {
      path: "/hubb",
      redirect: { name: "work-orders" },
    },
    {
      path: "/mobile",
      redirect: { name: "work-orders" },
    },
    {
      path: "/start",
      redirect: { name: "work-orders" },
    },
    {
      path: "/signin",
      name: "signin",
      meta: { skipUserInit: true, title: "Logga in" },
      component: UserAuth,
      props: {
        login: true,
      },
    },
    {
      path: "/signout",
      name: "signout",
      meta: { skipUserInit: true, title: "Logga ut" },
      component: UserAuth,
      props: {
        login: false,
      },
    },
    {
      path: "/authentication/silent-login",
      name: "authentication-silent-login",
      meta: { skipUserInit: true, title: "Loggar in..." },
      beforeEnter: async () => {
        const userManager = createUserManager();
        await userManager.signinSilentCallback();
      },
      component: SilentLogin,
    },
    {
      path: "/callback",
      name: "callback",
      meta: { skipUserInit: true, title: "Loggar in..." },
      component: CallbackPage,
    },
    {
      path: "/unauthorized",
      name: "Unauthorized",
      meta: { skipUserInit: true, title: "Behörighet saknas" },
      component: UnauthorizedPage,
    },
    {
      path: "/noaccess",
      name: "noaccess",
      meta: { title: "Ingen åtkomst" },
      component: () => import("@/modules/authentication/NoAccess.vue"),
    },
    {
      path: "/create",
      name: "create",
      props: (route) => ({
        cloneWorkOrderId: route.query.cloneWorkOrderId,
        projectNumber: route.query.projectNumber,
        zendeskReference: route.query.zendeskReference,
        customerNumber: route.query.customerNumber,
      }),
      meta: {
        requiresAuth: true,
        title: "Skapa order",
      },
      component: () => import("@/modules/workorder-create/views/CreateWorkOrder.vue"),
      children: [
        {
          path: "customer-search",
          name: "CustomerSearch",
          props: (route) => ({ initialSearchTerm: route.query.q }),
          meta: { requiresAuth: true, title: "Sök kund" },
          component: () => import("@/modules/customer/views/CustomerSearch.vue"),
        },
        {
          path: "customer-credit-safe",
          name: "CustomerCreditSafeSearch",
          props: (route) => ({
            titleProp: route.query?.title,
            customerTypeProp: Number(route.query?.customerType),
          }),
          meta: { requiresAuth: true, title: "Kreditsök" },
          component: () => import("@/modules/customer/views/CustomerCreditSafeSearch.vue"),
        },
        {
          path: "create-customer",
          name: "CreateCustomer",
          props: () => {
            const customerStore = useCreateCustomerStore();
            return {
              selectedCreditSafeItem: {
                kind: customerStore.kind,
                data: customerStore.data,
              },
              customerType: customerStore.customerType,
            };
          },
          meta: { requiresAuth: true, title: "Skapa kund" },
          beforeEnter: () => {
            const customerStore = useCreateCustomerStore();

            // We'll need a credit safe customer before we can create a real one
            if (customerStore.kind === "empty") {
              return {
                name: "CustomerSearch",
              };
            }

            return true;
          },
          component: () => import("@/modules/customer/views/CreateCustomer.vue"),
        },
      ],
    },

    {
      path: "/customers/create",
      name: "CustomerCreate",
      props: () => {
        const customerStore = useCreateCustomerStore();
        return {
          selectedCreditSafeItem: {
            kind: customerStore.kind,
            data: customerStore.data,
          },
          customerType: customerStore.customerType,
        };
      },
      meta: {
        requiresAuth: true,
        title: "Skapa ny kund",
      },
      beforeEnter: () => {
        const customerStore = useCreateCustomerStore();

        // We'll need a credit safe customer before we can create a real one
        if (customerStore.kind === "empty") {
          return {
            name: "CustomerSearch",
          };
        }

        return true;
      },
      component: () => import("@/modules/customer/views/CustomerCreateView.vue"),
    },
    {
      path: "/customers/search",
      name: "CustomerCreditSafeSearchView",
      props: (route) => ({
        titleProp: route.query?.title,
        customerTypeProp: Number(route.query?.customerType),
      }),
      meta: { requiresAuth: true, title: "Kreditsök" },
      component: () => import("@/modules/customer/views/CustomerCreditSafeSearchView.vue"),
    },

    {
      path: "/customers",
      name: "customers",
      meta: {
        requiresAuth: true,
        title: "Kunder",
      },
      component: () => import("@/modules/customer/views/CustomersView.vue"),
      props: (route) => ({ searchTerm: route.query.q ?? undefined }),
    },
    {
      path: "/customers/:customerNumber",
      name: "customer",
      props: {
        default: true,
        appbar: (route) => ({
          id: route.params.customerNumber,
        }),
      },
      meta: {
        requiresAuth: true,
        title: "Kund",
      },
      components: {
        default: () => import("@/modules/customer/views/CustomerDetails.vue"),
        appbar: () => import("@/modules/customer/components/CustomerHeader.vue"),
      },
    },

    {
      path: "/work-orders",
      name: "work-orders",
      meta: {
        requiresAuth: true,
        title: "Arbetsordrar",
      },
      component: () => import("@/modules/workorder-list/views/WorkOrderSearch.vue"),
      props: (route) => {
        const searchOptions = makeWorkOrderSearchParamsFromRoute(route.query);
        const store = useWorkOrderSearchStore();
        store.saveSearchOptions(searchOptions);

        return {
          searchOptions: searchOptions,
        };
      },
    },
    {
      path: "/work-orders/lookup",
      name: "work-orders-lookup",
      meta: { requiresAuth: true, title: "Söker arbetsorder..." },
      props: (route) => ({ workOrderNumber: route.query.workOrderNumber }),
      component: () => import("@/modules/workorder-details/views/WorkOrderLookup.vue"),
    },
    {
      path: "/risk-assessment/:workOrderId/create",
      name: "risk-assessment-create",
      meta: {
        requiresAuth: true,
        title: "Skapa riskbedömning",
      },
      props: (route) => ({
        workOrderId: route.params.workOrderId,
        riskAssessmentId: route.query.riskAssessmentId ?? null,
      }),
      component: () => import("@/modules/risks/views/CreateRiskAssessment.vue"),
    },
    {
      path: "/projects",
      name: "projects",
      meta: {
        requiresAuth: true,
        title: "Projekt",
      },
      component: () => import("@/modules/projects/views/ProjectSearch.vue"),
      props: (route) => {
        const searchOptions = makeProjectSearchParametersFromRoute(route.query);
        return {
          searchOptions: searchOptions,
        };
      },
    },
    {
      path: "/projects/:projectId",
      props: {
        default: true,
        appbar: (route) => ({
          id: route.params.projectId,
        }),
      },
      meta: {
        requiresAuth: true,
        title: "Projekt",
      },
      components: {
        default: () => import("@/modules/projects/views/ProjectDetails.vue"),
        appbar: () => import("@/modules/projects/components/ProjectHeader.vue"),
      },
      children: [
        {
          path: "",
          name: "project",
          props: true,
          meta: {
            requiresAuth: true,
            title: "Projekt",
          },
          component: () => import("@/modules/projects/views/ProjectWorkOrderList.vue"),
        },
        {
          path: "chart",
          name: "project-chart",
          props: (route) => {
            const defaultDate = chartDefaultDates(new Date());
            return {
              projectId: route.params.projectId,
              ...defaultDate,
            };
          },
          meta: {
            requiresAuth: true,
            title: "Schema",
          },
          component: () => import("@/modules/projects/views/ProjectChart.vue"),
        },
      ],
    },
    {
      path: "/work-orders/:id",
      props: {
        default: true,
        appbar: (route) => ({
          id: route.params.id,
        }),
      },
      meta: {
        requiresAuth: true,
        title: "Arbetsorder",
      },
      components: {
        default: () => import("@/modules/workorder-details/views/WorkOrderDetails.vue"),
        appbar: () => import("@/modules/workorder-details/components/WorkOrderHeader.vue"),
      },
      children: [
        {
          path: "",
          name: "work-order",
          props: true,
          meta: {
            requiresAuth: true,
            title: "Arbetsorder",
            view: "tabs",
          },
          component: () => import("@/modules/workorder-details/components/WorkOrderSummary.vue"),
        },
        {
          path: "invoices",
          name: "work-order-invoices",
          props: true,
          meta: {
            requiresAuth: true,
            title: "Faktura",
            view: "tabs",
          },
          component: () => import("@/modules/invoices/components/WorkOrderInvoiceTab.vue"),
        },
        {
          path: "time-reports",
          name: "work-order-time-reports",
          meta: {
            requiresAuth: true,
            title: "Tidrapporter",
            view: "tabs",
          },
          component: () => import("@/modules/timereport/components/WorkOrderTimeReportTab.vue"),
        },
        {
          path: "files",
          name: "work-order-files",
          props: true,
          meta: {
            requiresAuth: true,
            title: "Filer",
            view: "tabs",
          },
          component: () => import("@/modules/files/components/WorkOrderFileTab.vue"),
        },
        {
          path: "risks",
          name: "work-order-risks",
          props: true,
          meta: {
            requiresAuth: true,
            title: "Riskbedömning",
            view: "tabs",
          },
          component: () => import("@/modules/risks/views/WorkOrderRiskTab.vue"),
        },
        {
          path: "expense-report",
          name: "work-order-expense-report",
          meta: {
            requiresAuth: true,
            title: "Omkostnader",
            view: "tabs",
          },
          component: () => import("@/modules/expenses/components/WorkOrderCostsTab.vue"),
        },
        {
          path: "tools",
          name: "work-order-tools",
          props: true,
          meta: {
            requiresAuth: true,
            title: "Verktyg",
            view: "tabs",
          },
          component: () => import("@/modules/tools/components/WorkOrderToolsTab.vue"),
        },
        {
          path: "qr-code",
          name: "work-order-qr-code",
          props: true,
          meta: {
            requiresAuth: true,
            title: "QR-kod",
            view: "tabs",
          },
          component: () => import("@/modules/workorder-details/views/WorkOrderQRCode.vue"),
        },
        {
          path: "self-inspections",
          name: "work-order-self-inspections",
          props: true,
          meta: {
            requiresAuth: true,
            title: "Egenkontroller",
            view: "tabs",
          },
          component: () => import("@/modules/self-inspections/views/WorkOrderSelfInspectionsTab.vue"),
        },
        {
          path: "self-inspections/create",
          name: "work-order-self-inspections-create",
          props: true,
          meta: {
            requiresAuth: true,
            title: "Egenkontroller",
            view: "tabs",
          },
          component: () => import("@/modules/self-inspections/views/SelfInspectionCreateView.vue"),
        },
        {
          path: "self-inspections/pre",
          name: "work-order-self-inspections-pre-view",
          props: true,
          meta: {
            requiresAuth: true,
            title: "Egenkontroller",
            view: "tabs",
          },
          component: () => import("@/modules/self-inspections/views/PreInspectionView.vue"),
        },
        {
          path: "self-inspections/pre/edit",
          name: "work-order-self-inspections-pre-edit",
          props: true,
          meta: {
            requiresAuth: true,
            title: "Egenkontroller",
            view: "tabs",
          },
          component: () => import("@/modules/self-inspections/views/PreInspectionEdit.vue"),
        },
        {
          path: "self-inspections/post",
          name: "work-order-self-inspections-post-view",
          props: true,
          meta: {
            requiresAuth: true,
            title: "Egenkontroller",
            view: "tabs",
          },
          component: () => import("@/modules/self-inspections/views/PostInspectionView.vue"),
        },
        {
          path: "self-inspections/post/edit",
          name: "work-order-self-inspections-post-edit",
          props: true,
          meta: {
            requiresAuth: true,
            title: "Egenkontroller",
            view: "tabs",
          },
          component: () => import("@/modules/self-inspections/views/PostInspectionEdit.vue"),
        },
        ...reportRoutes,
      ],
    },
    { path: "/appointments", redirect: "/agenda" },
    {
      path: "/agenda",
      name: "agenda",
      props: (route) => ({
        date: route.query.date ?? new Date().toISOString().split("T")[0],
      }),
      meta: {
        requiresAuth: true,
        title: "Agenda",
      },
      component: () => import("@/modules/agenda/views/AgendaPage.vue"),
    },
    {
      path: "/staff-overview",
      name: "staff-overview",
      props: (route) => ({
        week: typeof route.query.week === "string" ? parseDate(route.query.week) : new Date(),
      }),
      meta: {
        title: "Tidrapporter",
        requiresAuth: true,
      },
      component: () => import("@/modules/staffoverview/views/StaffOverview.vue"),
    },
    {
      path: "/staff-overview/:userSignature",
      name: "staff-details",
      meta: {
        requiresAuth: true,
        title: "Personal",
      },
      props: (route) => ({
        userSignature: route.params.userSignature,
        week: typeof route.query.week === "string" ? parseDate(route.query.week) : new Date(),
      }),
      component: () => import("@/modules/staffoverview/views/StaffDetails.vue"),
    },
    {
      path: "/expense-overview",
      name: "expense-overview",
      meta: {
        requiresAuth: true,
        restrictToRoles: ["se.eaztimate.hubb.arbetsledare"],
        title: "Omkostnader",
      },
      props: (route) => ({
        date: typeof route.query.date === "string" ? parseDate(route.query.date) : new Date(),
        showMyWorkOrders: route.query.showAllWorkOrders !== "true",
      }),
      component: () => import("@/modules/expenseoverview/views/ExpenseOverview.vue"),
    },
    {
      path: "/remote-measurement/add-sensor",
      name: "add-sensor",
      meta: {
        title: "Lägg till sensor",
        requiresAuth: true,
      },
      props: (route) => ({
        workOrderId: route.query.workOrderId,
        returnUrl: route.query.returnUrl,
      }),
      component: () => import("@/modules/remote-measurement/views/AddSensorToWorkOrderPage.vue"),
    },
    {
      path: "/remote-measurement/:workOrderId/sensor/:sensorId",
      name: "work-order-sensor-details",
      meta: {
        title: "Fjärrmätare",
        requiresAuth: true,
      },
      props: (route) => ({
        sensorId: route.params.sensorId,
        workOrderId: route.params.workOrderId,
      }),
      component: () => import("@/modules/remote-measurement/views/SensorDetails.vue"),
    },
    {
      name: "zendesk",
      path: "/zendesk/:zendeskTicketId",
      props: (route) => ({
        zendeskTicketId: route.params.zendeskTicketId,
      }),
      component: () => import("@/modules/zendesk/views/ZendeskLandingPage.vue"),
      meta: {
        title: "Zendesk",
        requiresAuth: true,
      },
    },
    { path: "/:pathMatch(.*)*", name: "not-found", component: () => import("@/components/PageNotFound.vue") },
  ],
});

router.beforeEach(async (to, _, next) => {
  const store = useAuthenticatedUserStore();

  function hasAccess(meta: RouteLocationNormalized["meta"]) {
    if (!store.user.roles.includes("se.eaztimate.hubb")) {
      return false;
    }

    const restrictToRoles = meta?.restrictToRoles;
    return !restrictToRoles || restrictToRoles.some((r: UserRole) => store.user.roles.includes(r));
  }

  // ensure that store.state.user is initialized with any current user and roles
  const { requiresAuth, skipUserInit, title } = to.meta ?? {};
  document.title = makeTitle(title);

  if (requiresAuth || !skipUserInit) {
    // we need this if requiresAuth == true but may otherwise skip it if skipUserInit == true
    await store.userInit();
  }

  if (requiresAuth) {
    if (store.user.isAuthenticated) {
      if (!hasAccess(to.meta)) {
        next({ name: "noaccess" });
        return;
      }
    } else {
      applicationInsightsInstance().trackTrace({
        message: "Authentication is required but the user is not. sending the user to the login",
        severityLevel: 2,
      });
      await store.userLogin(to.fullPath);
      next(false);
      return;
    }
  }

  if (to.meta?.accessLevel === "beta" && !store.isBetaGroup) {
    next({ name: "noaccess" });
    return;
  }

  next();
});

router.afterEach(() => {
  intercomUpdate();
});

export default router;
