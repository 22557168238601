<script setup lang="ts">
import { provide } from "vue";
import toasterInjectionKey from "./injectionKey";
import ToastContainer from "./ToastContainer.vue";
import useToaster from "./useToaster";
import ToastMessage from "@/components/Toaster/ToastMessage.vue";

const { toast: displayToast, close: closeToast, toasts } = useToaster();

function toastError(message: string, displayTime?: number) {
  displayToast({ intent: "error", message, displayTime });
}

function toastSuccess(message: string, displayTime?: number) {
  displayToast({ intent: "success", message, displayTime });
}

function toastWarning(message: string, displayTime?: number) {
  displayToast({ intent: "warning", message, displayTime });
}

function toastInfo(message: string, displayTime?: number) {
  displayToast({ intent: "info", message, displayTime });
}

const toast = { error: toastError, info: toastInfo, warning: toastWarning, success: toastSuccess };

provide(toasterInjectionKey, toast);
</script>

<template>
  <slot name="default" />
  <ToastContainer v-if="toasts.length > 0">
    <ToastMessage v-for="message in toasts" :key="message.key" :toast="message" @dismiss="() => closeToast(message)" />
  </ToastContainer>
</template>

<style scoped></style>
