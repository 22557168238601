import { getTheme, setTheme } from "@/services/userSettings";
import { computed, ref } from "vue";
import { useTheme } from "vuetify";
import type { Theme } from "@/configuration/vuetify";

const selectedTheme = ref(getTheme());

export function useSelectedTheme() {
  const theme = useTheme();

  function setMode(newTheme: Theme) {
    setTheme(newTheme);
    selectedTheme.value = newTheme;
    theme.global.name.value = newTheme;
  }
  const isDarkMode = computed(() => selectedTheme.value === "dark");
  return {
    theme: selectedTheme,
    setMode,
    isDarkMode,
  };
}
