import type { Theme } from "@/configuration/vuetify";

export function useApiBetaVersion() {
  return localStorage.getItem("use-api-beta") === "true";
}

export function getTheme(): Theme {
  const persistedTheme = localStorage.getItem("theme");
  if (persistedTheme === "dark" || persistedTheme === "light") {
    return persistedTheme;
  }

  if (window.matchMedia("(prefers-color-scheme:dark)").matches) {
    return "dark";
  }

  return "light";
}

export function setTheme(theme: Theme) {
  localStorage.setItem("theme", theme);
}
