import { ref } from "vue";
import type { ToastArgs, ToastProps } from "./types";
import { useAuthenticatedUserStore } from "@/services/store";

const defaultDisplaytimes = {
  success: 3 * 1000,
  error: 120 * 1000,
  warning: 30 * 1000,
  info: 30 * 1000,
} as const;

// Using the global store to be able to get access from the http client.
export function globalToast(args: ToastArgs) {
  const store = useAuthenticatedUserStore();

  store.toastAdd(args);
}

export default function useToaster() {
  const store = useAuthenticatedUserStore();
  const toasts = ref<ToastProps[]>([]);

  store.$onAction((action) => {
    if (action.name === "toastAdd") {
      const [toastArg] = action.args;
      toast(toastArg);
    }
  });

  function makeKey(displayTime: number) {
    return Date.now() + displayTime;
  }

  function makeProps(args: ToastArgs): ToastProps {
    const displayTime = args.displayTime ?? defaultDisplaytimes[args.intent];
    return {
      message: args.message,
      intent: args.intent,
      displayTime: displayTime,
      key: makeKey(displayTime),
    };
  }

  function removeToast(toast: ToastProps) {
    const index = toasts.value.indexOf(toast);
    toasts.value.splice(index, 1);
  }

  function toast(args: ToastArgs) {
    const props = makeProps(args);
    toasts.value.push(props);
    setTimeout(() => removeToast(props), props.displayTime);
  }

  return { toast, toasts, close: removeToast };
}
