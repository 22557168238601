<script setup lang="ts">
import CreateWorkOrderButton from "./CreateWorkOrderButton.vue";
import { useSlots } from "vue";
import { useRoute, useRouter } from "vue-router";
import { configValue } from "@/services/config";
import { useAuthenticatedUserStore } from "@/services/store";
import { useNavigationDrawer } from "@/modules/root/app";
const store = useAuthenticatedUserStore();
const router = useRouter();
const environmentColor: string | null = {
  local: "#e7ffe6",
  test: "#f5f3ce",
  stage: "#fce8ea",
  prod: null,
}[configValue("VUE_APP_ENVIRONMENT")];

const appBarStyle = environmentColor ? { backgroundColor: environmentColor } : undefined;

const { toggleNavigationDrawer } = useNavigationDrawer();

const slots = useSlots();
const route = useRoute();
</script>

<template>
  <VAppBar elevation="1" :style="appBarStyle" class="fixedHeight">
    <slot name="title">
      <VAppBarNavIcon @click="toggleNavigationDrawer" />
      <VAppBarTitle class="text-primary text-decoration-none font-weight-bold text-body-1 text-sm-h6">
        {{ route.meta.title }}
      </VAppBarTitle>
      <VSpacer />
      <CreateWorkOrderButton
        v-if="
          store.hasAccessToWorkOrderCreate &&
          router.currentRoute.value.name !== 'create' &&
          router.currentRoute.value.name !== 'customers' &&
          router.currentRoute.value.name !== 'zendesk'
        "
        :to="{ name: 'create' }"
        class="mr-4"
      />
    </slot>
    <template v-if="slots.extension" #extension>
      <slot name="extension" />
    </template>
  </VAppBar>
</template>
