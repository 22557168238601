<template>
  <TransitionGroup tag="div" class="message-container" name="toast-list">
    <slot />
  </TransitionGroup>
</template>

<style scoped>
.message-container {
  /*
  z-index in Vuetify's Dialogs starts at 202
  */
  z-index: 210;
  position: fixed;
  bottom: 5dvh;
  right: 0;
  padding: 8px;
  max-width: 100dvw;
  width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.toast-list-move,
.toast-list-enter-active,
.toast-list-leave-active {
  transition: all 0.5s ease;
}

.toast-list-enter-from,
.toast-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.toast-list-leave-active {
  position: absolute;
}
</style>
