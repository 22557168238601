import {
  parseISO,
  addDays as fnsAddDays,
  format,
  setDefaultOptions,
  getYear,
  getMonth,
  format as formatDate,
  getWeek,
} from "date-fns";
import { sv } from "date-fns/locale";

const DATE_FNS_OPTIONS = { locale: sv };
const DATE_FNS_FORMATS = { day: "d", month: "MMM", year: "yyyy" };

setDefaultOptions(DATE_FNS_OPTIONS);

export function getSwedishDate(date: Date | string, withTime = true): string {
  function format(dateToFormat: Date) {
    return dateToFormat.toLocaleString("sv-SE", {
      dateStyle: "short",
      timeStyle: withTime ? "short" : undefined,
    });
  }

  if (typeof date === "string") {
    return format(new Date(date));
  }

  return format(date);
}

/**
 * Add days to a date and return a new date
 * @param {Date} date - the original date
 * @param days number of days to add
 * @return {Date} - New date object with 24h added.
 */
export function addDays(date: Date, days: number): Date {
  return fnsAddDays(date, days);
}

/**
 * Return the year-month-day part of the date
 * @param {Date} date - the original date
 * @return {string} - String of format YYYY-MM-DD
 */
export function toDateString(date: Date): string {
  return date.toLocaleDateString("sv-SE", { dateStyle: "short" });
}

export function parseDate(date: string) {
  return parseISO(date);
}

export function getFormattedWeekDays(date: Date) {
  const start = getStartOfWeek(date);
  const days = [...Array(7).keys()].map((i) => addDays(start, i));
  return days.map((d) => format(d, "EEEE d MMM", { locale: sv }).replace(".", ""));
}

export function getWeekNumber(date: Date) {
  const d = new Date(date.valueOf());
  d.setUTCDate(d.getDate() + 4 - (d.getDay() || 7));
  const yearStart = new Date(d.getFullYear(), 0, 1);
  const week = Math.ceil(((d.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7);
  return { week, year: d.getFullYear() };
}

export function getStartOfWeek(target: Date) {
  const dayOfTheMonth = target.getDate();
  const weekIndex = (target.getDay() + 6) % 7;
  const startOfTheWeek = new Date(target.valueOf());
  startOfTheWeek.setDate(dayOfTheMonth - weekIndex);
  startOfTheWeek.setHours(0, 0, 0, 0);
  return startOfTheWeek;
}

export function getEndOfWeek(target: Date) {
  const dayOfTheMonth = target.getDate();
  const weekIndex = (target.getDay() + 6) % 7;
  const endOfTheWeek = new Date(target.valueOf());
  endOfTheWeek.setDate(dayOfTheMonth + (6 - weekIndex));
  endOfTheWeek.setHours(0, 0, 0, 0);
  return endOfTheWeek;
}

export function getReadableDateSpan(start: Date, end: Date, includeWeek = true) {
  const startYear = getYear(start);
  const startMonth = getMonth(start);
  const endYear = getYear(end);
  const endMonth = getMonth(end);
  let startFormat = DATE_FNS_FORMATS.day;
  if (startMonth !== endMonth) {
    startFormat = startFormat + " " + DATE_FNS_FORMATS.month;
  }
  if (startYear !== endYear) {
    startFormat = startFormat + " " + DATE_FNS_FORMATS.year;
  }
  const endFormat = `${DATE_FNS_FORMATS.day} ${DATE_FNS_FORMATS.month} ${DATE_FNS_FORMATS.year}`;
  const startDate = formatDate(start, startFormat, { locale: sv });
  const endDate = formatDate(end, endFormat, { locale: sv });
  const result = `${startDate}-${endDate}`;
  if (includeWeek) {
    const week = getWeek(start);
    return `v${week}: ` + result;
  }
  return result;
}
