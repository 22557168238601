import { createVuetify, type VuetifyOptions } from "vuetify";
import "@/styles/main.scss";
import type { ThemeDefinition } from "vuetify";
import colors from "vuetify/util/colors";
import { sv } from "vuetify/locale";
import { md2 as blueprint } from "vuetify/blueprints";

const oleterThemeLight: ThemeDefinition = {
  dark: false,
  colors: {
    background: colors.grey.lighten3,
    surface: colors.grey.lighten5,
    primary: "#006CB7",
    "primary-darken-1": "#004D98",
    secondary: colors.teal.darken1,
    "secondary-darken-1": colors.teal.darken2,
    error: colors.red.darken1,
    info: colors.blue.base,
    success: colors.green.base,
    warning: colors.deepOrange.accent2,
    disabled: "#006CB7",
    //State color
    unknown: colors.grey.base,
    registered: colors.purple.base,
    inProgress: "#006CB7",
    waitingForVerification: colors.orange.base,
    workDone: colors.green.base,
    readyForInvoicing: colors.indigo.base,
    invoiced: "#006CB7",
    finished: colors.teal.base,
    deleted: colors.grey.base,
    cancelled: colors.red.base,
  },
};
const oleterThemeDark: ThemeDefinition = {
  dark: true,
  colors: {
    background: colors.grey.darken4,
    surface: colors.grey.darken4,
    primary: "#0067b0",
    "primary-darken-1": "#004D98",
    secondary: "#0067b0",
    "secondary-darken-1": "#0067b0",
    error: colors.deepOrange.accent2,
    info: "#0067b0",
    success: colors.green.base,
    warning: colors.deepOrange.accent2,
    disabled: "#ffff",
    //State color
    unknown: colors.grey.base,
    registered: "#ffff",
    inProgress: "#ffff",
    waitingForVerification: "#ffff",
    workDone: "#ffff",
    readyForInvoicing: "#ffff",
    invoiced: "#ffff",
    finished: "#ffff",
    deleted: colors.deepOrange.accent2,
    cancelled: colors.deepOrange.accent2,
  },
};

export const vuetifyOptions: VuetifyOptions = {
  locale: {
    locale: "sv",
    messages: { sv },
  },
  display: {
    mobileBreakpoint: "sm",
  },
  blueprint: blueprint,
  defaults: {
    VBtn: { variant: "text" },
    VDialog: {
      scrollStrategy: "none",
    },
    VAutocomplete: {
      clearable: true,
    },
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light: oleterThemeLight,
      dark: oleterThemeDark,
    },
  },
};

export const createVuetifyPlugin = () => createVuetify(vuetifyOptions);

export type Theme = "dark" | "light";
