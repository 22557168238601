<script setup lang="ts">
import AppBar from "./components/AppBar.vue";
import { GlobalToaster } from "@/components/Toaster";
import { useBreakpoint } from "@/helpers/useBreakpoint";
import NavigationDrawer from "@/modules/root/components/NavigationDrawer.vue";
import { initializeNavigationDrawer } from "@/modules/root/app";
import { useSelectedTheme } from "./components/UseTheme";
const breakpoint = useBreakpoint();
const { theme } = useSelectedTheme();

const showNavigationDrawer = initializeNavigationDrawer(!breakpoint.value.mobile);
</script>
<template>
  <VApp :theme="theme">
    <NavigationDrawer v-model="showNavigationDrawer" />
    <RouterView v-slot="{ Component }" name="appbar">
      <Transition name="router-fade" mode="out-in">
        <KeepAlive>
          <component :is="Component ?? AppBar" v-model:show-navigation-drawer="showNavigationDrawer" />
        </KeepAlive>
      </Transition>
    </RouterView>
    <VMain>
      <VContainer :fluid="breakpoint.mobile" :class="{ 'px-0': breakpoint.mobile }">
        <GlobalToaster>
          <RouterView />
        </GlobalToaster>
      </VContainer>
    </VMain>
  </VApp>
</template>
