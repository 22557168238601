import { requireAuthenticatedUser } from "@/services/oidcManager";
import { configValue } from "@/services/config";
import { useApiBetaVersion } from "@/services/userSettings";
import resolveHttpErrorHandler, { type ErrorHandler } from "@/services/http-error-resolver";
import axios, { type AxiosError } from "axios";
import { stringify } from "qs";
import { globalToast } from "@/components/Toaster/useToaster";
import { createUnknownError } from "@/services/custom-errors";
import { getSelectedCompany } from "@/services/store";

export function stringifyOptions(params: object) {
  return stringify(params, { indices: false, skipNulls: true });
}

const httpClient = axios.create({
  baseURL: configValue("VUE_APP_SKA_API_EP"),
});

httpClient.interceptors.request.use(
  async (config) => {
    const user = await requireAuthenticatedUser();
    config.headers["Authorization"] = "Bearer " + user.access_token;

    const selectedCompany = getSelectedCompany();
    if (selectedCompany) {
      config.headers["EZ-CompanyId"] = selectedCompany;
    }

    if (useApiBetaVersion()) {
      config.headers["EZ-API-Beta"] = true;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

httpClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error: unknown) {
    const reason = resolveUnknownError(error);

    // We can suppress the toast by setting the request option "notifyError" to false
    if (!(axios.isAxiosError(error) && error.config?.notifyError === false)) {
      globalToast({ intent: "error", message: reason.localizedMessage, displayTime: 60 * 1000 });
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return Promise.reject(reason);
  },
);

function resolveUnknownError(error: unknown) {
  if (axios.isAxiosError(error)) {
    return resolveHttpErrorHandler(error);
  }

  return createUnknownError(error);
}

export type HttpError = AxiosError & {
  config?: {
    errorHandlers?: ErrorHandler[];
  };
  response?: {
    data?: {
      type?: string;
      status?: number;
    };
  };
};

export default httpClient;
