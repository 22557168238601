/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface UserMeResponse {
  isAuthenticated: boolean;
  roles: string[];
  profile?: UserProfile | null;
  companies: CompanyInformation[];
}

export interface UserProfile {
  /** @format int32 */
  userId: number;
  name: string;
  email: string;
  mainCompany: CompanyInformation;
  signature: string;
}

export interface CompanyInformation {
  id: string;
  name: string;
}

export interface ApplicationVersion {
  version?: string | null;
}

export interface CreateCustomerResponse {
  customerNumber: string;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  extensions: Record<string, any>;
  [key: string]: any;
}

export interface CreateCustomerModel {
  name: string;
  address: CustomerAddressModel;
  organizationNumber: string;
  email?: string | null;
  customerGroupId: string;
}

export interface CustomerAddressModel {
  address1?: string | null;
  address2?: string | null;
  zip: string;
  city: string;
}

export interface CreditCheckCompanyModel {
  status: CreditCheckCompanyStatus;
  name: string;
  address: CustomerAddressModel;
  organizationNumber: string;
  companyType: string;
  isApproved: boolean;
}

export enum CreditCheckCompanyStatus {
  Positive = 0,
  Negative = 1,
  Neutral = 2,
}

export interface CreditCheckConsumerModel {
  status: CreditCheckConsumerStatus;
  name: string;
  address: CustomerAddressModel;
  personalIdentityNumber: string;
  isApproved: boolean;
}

export enum CreditCheckConsumerStatus {
  Active = 0,
  Protected = 1,
  Locked = 2,
  Deceased = 3,
  RemovedFromRegister = 4,
  Emigrated = 5,
}

export interface CreditCheckResultModel {
  /** @format int32 */
  status: number;
  statusText: string;
  errors: string[];
}

export interface CreditCheckRequestModel {
  organizationNumber: string;
}

export interface CustomerSearchResultModel {
  customerNumber: string;
  name: string;
  address: CustomerAddressModel;
  organizationNumber: string;
  email: string;
  defaultPricelistId: string;
  group: CustomerGroup;
}

export interface CustomerGroup {
  id: string;
  name: string;
}

export interface CustomerWithCreditCheckModel {
  customer: CustomerSearchResultModel;
  creditCheck: CreditCheckResultModel;
}

export interface InsuranceCompanySearchModel {
  insuranceCompanyNumber: string;
  name: string;
  address: CustomerAddressModel;
  organizationNumber: string;
  email: string;
}

export interface ProjectChangeModel {
  companyId?: string | null;
  projectNumber?: string | null;
  userSignature?: string | null;
}

export interface WorkOrderChangeModel {
  companyId?: string | null;
  workOrderNumber?: string | null;
  userSignature?: string | null;
}

export interface SensorListModel {
  /** @format guid */
  id: string;
  name?: string | null;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  disconnectDate?: string | null;
  hardwareId: string;
  /** @format guid */
  workOrderId: string;
}

export enum SensorStatus {
  Any = 0,
  Active = 1,
  Disconnected = 2,
}

export interface AddSensorResultModel {
  /** @format guid */
  id: string;
}

export interface AddSensorModel {
  hardwareId: string;
  name?: string | null;
  /** @format int32 */
  targetValue?: number | null;
}

export interface SensorModel {
  /** @format guid */
  id: string;
  name?: string | null;
  hardwareId: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  disconnectDate?: string | null;
  /** @format date-time */
  lastContactDate?: string | null;
  /** @format int32 */
  batteryLevel?: number | null;
  /** @format int32 */
  signalQuality: number;
  measurementPoints: MeasurementPointModel[];
  /** @format int32 */
  targetValue?: number | null;
}

export interface MeasurementPointModel {
  type: string;
  unit: string;
  /** @format double */
  latestValue?: number | null;
}

export interface MeasurementPointHistoryModel {
  type: string;
  unit: string;
  values: MeasurementPointValueModel[];
}

export interface MeasurementPointValueModel {
  /** @format date-time */
  date: string;
  /** @format double */
  value: number;
}

export interface RiskAssessmentResultModel {
  /** @format date-time */
  createdDate: string;
  /** @format int32 */
  version: number;
  comment?: string | null;
  lastEditedBy: RiskUserModel;
  training: SelectedItemsModel;
  gear: SelectedItemsModel;
  risks: SelectedRisksModel;
}

export interface RiskUserModel {
  firstName: string;
  lastName: string;
  signature: string;
  email?: string | null;
}

export interface SelectedItemsModel {
  list: AssessmentItemModel[];
  extra?: string | null;
}

export interface AssessmentItemModel {
  title: string;
}

export interface SelectedRisksModel {
  list: AssessmentRiskModel[];
  extra?: string | null;
}

export interface AssessmentRiskModel {
  title: string;
  answers: RiskTopicAnswerModel[];
}

export interface RiskTopicAnswerModel {
  question: string;
  information?: string | null;
  questionType: string;
  answer?: string | null;
  answers: string[];
  comment?: string | null;
}

export interface RiskAssessmentEditModel {
  comment?: string | null;
  gear: EditSelectedItemsModel;
  training: EditSelectedItemsModel;
  risk: EditSelectedItemsModel;
  answers: Record<string, RiskAnswerModel[]>;
}

export interface EditSelectedItemsModel {
  extra?: string | null;
  selected: RiskItemModel[];
}

export interface RiskItemModel {
  /** @format guid */
  uuid: string;
  title: string;
  constName: string;
  /** @format int32 */
  sortOrder: number;
}

export interface RiskAnswerModel {
  question: string;
  answer?: string | null;
  answerArray: string[];
  comment?: string | null;
  info?: string | null;
  questionType: string;
  /** @format int32 */
  sortOrder: number;
  /** @format guid */
  questionId: string;
  /** @format guid */
  possibleRiskId: string;
}

export interface WorkOrderRiskAssessmentModel {
  riskAssessments: RiskAssessmentListModel[];
  /**
   * @deprecated
   * @format guid
   */
  riskAssessmentId?: string | null;
  signedByUsers: RiskUserModel[];
}

export interface RiskAssessmentListModel {
  /** @format guid */
  riskAssessmentId: string;
  /** @format int32 */
  version: number;
  /** @format date-time */
  createdDate: string;
  createdBy: RiskUserModel;
}

export interface SelectedRiskItemResultModel {
  list: RiskItemModel[];
}

export interface RiskQuestionResultModel {
  list: RiskQuestionModel[];
  links: RiskLinkModel[];
}

export interface RiskQuestionModel {
  /** @format guid */
  questionId: string;
  /** @format guid */
  possibleRiskId: string;
  /** @format guid */
  uuid: string;
  question: string;
  info?: string | null;
  commentHint?: string | null;
  /** @format int32 */
  sortOrder: number;
  questionType: string;
  answers: string[];
}

export interface RiskLinkModel {
  title: string;
  url: string;
}

export interface RiskAddResultModel {
  success: boolean;
  message: string;
}

export interface RiskAssessmentModel {
  /** @format guid */
  uuid: string;
  comment?: string | null;
  answers: RiskAnswerModel[];
  risks: RiskItemModel[];
  protectiveGear: RiskItemModel[];
  training: RiskItemModel[];
  extraRisk?: string | null;
  extraProtectiveGear?: string | null;
  extraTraining?: string | null;
}

export interface InspectionModel {
  /** @format date-time */
  lastAnswerDate?: string | null;
  lastAnsweredBy?: InspectionUserModel | null;
  workTypeId?: string | null;
  workSize?: WorkSize | null;
  /** @format int32 */
  version: number;
  questions: QuestionModel[];
  answers: AnswerModel[];
  state: InspectionState;
}

export interface InspectionUserModel {
  firstName: string;
  lastName: string;
  signature: string;
  email?: string | null;
}

export enum WorkSize {
  Small = 0,
  Large = 1,
}

export interface QuestionModel {
  id: string;
  title: string;
  description: string;
  type: QuestionType;
}

export enum QuestionType {
  YesNo = 0,
}

export interface AnswerModel {
  questionId: string;
  answer: AnswerDataModel;
}

export interface AnswerDataModel {
  value: string;
  comment?: string | null;
}

export enum InspectionState {
  Missing = 0,
  Started = 1,
  Partial = 2,
  Completed = 3,
}

export enum InspectionStage {
  Pre = 0,
  Post = 1,
}

export interface CreateInspectionModel {
  workTypeId: string;
  workSize: WorkSize;
}

export interface AgendaListModel {
  /** @format date-time */
  date: string;
  workOrder: AgendaWorkOrderModel;
  appointment?: AgendaAppointmentModel | null;
  timeReport: AgendaTimeReportModel;
  expenseReport: AgendaExpenseReportModel;
}

export interface AgendaWorkOrderModel {
  /** @format guid */
  workOrderId: string;
  workOrderNumber: string;
  address?: string | null;
  city?: string | null;
}

export interface AgendaAppointmentModel {
  /** @format guid */
  appointmentId: string;
  /** @format duration */
  startTime?: string | null;
  /** @format duration */
  endTime?: string | null;
  assignedUsers: UserModel[];
}

export interface UserModel {
  firstName: string;
  lastName: string;
  signature: string;
  email?: string | null;
}

export interface AgendaTimeReportModel {
  /** @format decimal */
  totalHours: number;
}

export interface AgendaExpenseReportModel {
  /** @format int32 */
  count: number;
}

export interface AgendaScheduleModel {
  /** @format date-time */
  date: string;
  /** @format decimal */
  expectedHours: number;
}

export interface AddCommentModel {
  text: string;
  mentionedUsers: UserModel[];
}

export interface CommentModel {
  /** @format guid */
  id: string;
  author: UserModel;
  /** @format date-time */
  createdDate: string;
  text: string;
  mentionedUsers: UserModel[];
}

export interface ContactModel {
  /** @format guid */
  id?: string | null;
  name?: string | null;
  phone?: string | null;
  phone2?: string | null;
  email?: string | null;
  role?: ContactRoleModel | null;
}

export interface ContactRoleModel {
  id: string;
  name: string;
}

export interface UpdateContactModel {
  name: string;
  phone?: string | null;
  phone2?: string | null;
  email?: string | null;
  role: ContactRoleModel;
}

export interface ContactSearchModel {
  name?: string | null;
  phone?: string | null;
  phone2?: string | null;
  email?: string | null;
  role?: ContactRoleModel | null;
}

export interface CreateWorkOrderResponseModel {
  /** @format guid */
  id: string;
  workOrderNumber: string;
}

export interface CreateJourWorkOrderModel {
  companyId: string;
  creatorSignature: string;
  object: ObjectModel;
  customer: CustomerModel;
  contacts: JourContactModel[];
  finance: FinanceModel;
  order: JourOrderModel;
}

export interface ObjectModel {
  title?: string | null;
  address: AddressModel;
  apartmentNumber?: string | null;
  propertyEntryCode?: string | null;
  propertyEntryInformation?: string | null;
}

export interface AddressModel {
  address1?: string | null;
  address2?: string | null;
  zip?: string | null;
  city?: string | null;
}

export interface CustomerModel {
  name?: string | null;
  address: AddressModel;
  email?: string | null;
  customerNumber?: string | null;
  organizationNumber?: string | null;
  legalEntityType?: string | null;
  creditCheckStatus: CreditCheckStatus;
}

export enum CreditCheckStatus {
  NotChecked = 0,
  Approved = 1,
  NotApproved = 2,
  Grade = 4,
  NotNeeded = 5,
}

export interface JourContactModel {
  /** @format guid */
  id?: string | null;
  name: string;
  phone?: string | null;
  phone2?: string | null;
  email?: string | null;
  roleId: string;
}

export interface FinanceModel {
  isFixedPrice?: boolean | null;
  /** @format decimal */
  fixedPrice?: number | null;
  agreement?: string | null;
  pricelist?: PricelistModel | null;
  invoiceReferenceNumber?: string | null;
  invoiceText?: string | null;
  invoiceReferenceName?: string | null;
}

export interface PricelistModel {
  id: string;
  pricelistNumber: string;
  name: string;
}

export interface JourOrderModel {
  workDescription: string;
  foremanSignature?: string | null;
  workTypeId: string;
  departmentCode: string;
  administratorSignature: string;
  mainTechnicianSignature?: string | null;
}

export interface CreateWorkOrderModel {
  project?: WorkOrderProjectModel | null;
  object: ObjectModel;
  customer: CustomerModel;
  contacts: ContactModel[];
  finance: FinanceModel;
  order: OrderModel;
  planning: PlanningModel;
  insuranceClaim: InsuranceClaimModel;
  meps: MepsModel;
  zendesk?: ZendeskModel | null;
  sendConfirmationEmail: boolean;
}

export interface WorkOrderProjectModel {
  /** @format guid */
  id: string;
  projectNumber: string;
}

export interface OrderModel {
  workDescription?: string | null;
  foreman?: UserModel | null;
  workType?: WorkTypeModel | null;
  department: DepartmentModel;
  administrator?: UserModel | null;
  mainTechnician?: UserModel | null;
  labels?: string[] | null;
  /** @format int32 */
  commentCount: number;
  shortDescription?: string | null;
}

export interface WorkTypeModel {
  id: string;
  name: string;
}

export interface DepartmentModel {
  departmentCode: string;
  name: string;
}

export interface PlanningModel {
  /** @format date-time */
  plannedStartDate?: string | null;
  /** @format date-time */
  plannedEndDate?: string | null;
  /** @format decimal */
  expectedHoursReported?: number | null;
  /** @format int32 */
  appointmentCount: number;
}

export interface InsuranceClaimModel {
  hasInsuranceClaim: boolean;
  policyHolderName?: string | null;
  /** @format decimal */
  deductible?: number | null;
  insuranceCompany?: InsuranceCompanyModel | null;
}

export interface InsuranceCompanyModel {
  insuranceCompanyNumber?: string | null;
  name?: string | null;
}

export interface MepsModel {
  reference?: string | null;
}

export interface ZendeskModel {
  reference?: string | null;
}

export interface AddLabelModel {
  name: string;
}

export interface ExpenseArticleRelationModel {
  articleNumber: string;
  name: string;
  recommendations: ExpenseReportArticleRecommendationModel[];
}

export interface ExpenseReportArticleRecommendationModel {
  articleNumber: string;
  name: string;
  unit: string;
  sourceOfRecommendation: string;
  /** @format decimal */
  quantity?: number | null;
}

export interface CreateExpenseReportOptionsModel {
  expenseItems: ExpenseItemModel[];
}

export interface ExpenseItemModel {
  id?: string | null;
  name?: string | null;
  unit?: string | null;
}

export interface ExpenseReportArticleModel {
  articleNumber: string;
  name: string;
  unit: string;
}

export interface ExpenseOverviewWorkOrderModel {
  /** @format guid */
  workOrderId: string;
  workOrderNumber: string;
  address?: string | null;
  city?: string | null;
  department: DepartmentModel;
  hasAppointment: boolean;
  timeReporters: ExpenseOverviewTimeReporterModel[];
  expenseReports: ExpenseOverviewExpenseReportModel[];
}

export interface ExpenseOverviewTimeReporterModel {
  user: UserModel;
  timeReports: ExpenseOverviewTimeReportModel[];
}

export interface ExpenseOverviewTimeReportModel {
  timeType: TimeTypeModel;
  /** @format decimal */
  hours: number;
  comment: string;
}

export interface TimeTypeModel {
  id: string;
  name: string;
}

export interface ExpenseOverviewExpenseReportModel {
  createdBy: UserModel;
  article: ExpenseReportArticleModel;
  /** @format decimal */
  quantity: number;
  comment?: string | null;
}

export interface FileModel {
  /** @format guid */
  id: string;
  type: FileType;
  title: string;
  mimeType?: string | null;
  url: string;
  thumbnailUrl?: string | null;
  createdDate: string;
  size?: string | null;
  attachedToInvoice: boolean;
}

export enum FileType {
  Document = 0,
  Image = 1,
}

export interface DownloadFileModel {
  /** @format guid */
  id: string;
  type: FileType;
}

export interface HistoryModel {
  /** @format guid */
  id: string;
  name: string;
  user?: HistoryUserModel | null;
  /** @format date-time */
  createdDate: string;
  sourceSystem: SourceSystem;
  data: Record<string, string | null>;
}

export interface HistoryUserModel {
  signature: string;
  firstName: string;
  lastName: string;
  email?: string | null;
}

export enum SourceSystem {
  Hubb = 0,
  Erp = 1,
  Planner = 2,
}

export interface WorkOrderOptionsModel {
  pricelists: PricelistOptionsModel[];
  departments: DepartmentModel[];
  foremen: UserModel[];
  mainTechnicians: UserModel[];
  administrators: UserModel[];
  workTypes: WorkTypeModel[];
  defaultForeman?: UserModel | null;
  canCreateWorkOrder: boolean;
}

export interface PricelistOptionsModel {
  id: string;
  pricelistNumber: string;
  name: string;
  isDefault: boolean;
}

export interface ProjectListModel {
  /** @format guid */
  id: string;
  projectNumber: string;
  address?: string | null;
  department: DepartmentModel;
  workType?: WorkTypeModel | null;
  administrator?: UserModel | null;
  foreman?: UserModel | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  erpStatus: ProjectListModelErpStatus;
}

export enum ProjectListModelErpStatus {
  Unknown = 0,
  Registered = 1000,
  InProgress = 2000,
  WorkDone = 3000,
  ReadyForInvoicing = 4000,
  Finished = 5000,
  Deleted = 6000,
  Cancelled = 7000,
}

export interface ProjectListResultModel {
  entries: ProjectListModel[];
  /** @format int32 */
  total: number;
}

export enum ProjectSortFields {
  ProjectNumber = 0,
  StartDate = 1,
}

export enum SortDirection {
  Ascending = 0,
  Descending = 1,
}

export interface SubtaskModel {
  /** @format guid */
  id: string;
  /** @format guid */
  workOrderId: string;
  /** @format guid */
  projectId: string;
  title: string;
  description?: string | null;
  status: SubtaskStatus;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  color?: string | null;
  /** @format int32 */
  expectedHoursReported?: number | null;
}

export enum SubtaskStatus {
  NotStarted = 0,
  InProgress = 1,
  Done = 2,
}

export interface CreateSubtaskModel {
  /** @format guid */
  workOrderId: string;
  title: string;
  description?: string | null;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  color?: string | null;
  /** @format int32 */
  expectedHoursReported?: number | null;
}

export interface UpdateSubtaskModel {
  title: string;
  description?: string | null;
  status: SubtaskStatus;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  color?: string | null;
  /** @format int32 */
  expectedHoursReported?: number | null;
}

export interface CreateTimeReportOptionsModel {
  timeTypes: TimeTypeModel[];
  defaultTimeTypeId?: string | null;
  allowedDates: string[];
}

export interface TimeReportStaffOverviewModel {
  user: UserModel;
  departments: DepartmentModel[];
  days: TimeReportStaffOverviewDayModel[];
}

export interface TimeReportStaffOverviewDayModel {
  /** @format date-time */
  date: string;
  /** @format decimal */
  hours: number;
  /** @format decimal */
  expectedHours: number;
}

export interface TimeReportUserOverviewModel {
  user: UserModel;
  days: TimeReportUserOverviewDayModel[];
}

export interface TimeReportUserOverviewDayModel {
  /** @format date-time */
  date: string;
  /** @format decimal */
  hours: number;
  /** @format decimal */
  expectedHours: number;
  workOrders: TimeReportUserOverviewWorkOrderModel[];
  absence: TimeReportUserOverviewAbsenceModel[];
}

export interface TimeReportUserOverviewWorkOrderModel {
  /** @format guid */
  id: string;
  workOrderNumber: string;
  objectAddress: AddressModel;
  timeReports: TimeReportUserOverviewTimeReportModel[];
}

export interface TimeReportUserOverviewTimeReportModel {
  /** @format decimal */
  hours: number;
  timeType: TimeTypeModel;
  comment: string;
}

export interface TimeReportUserOverviewAbsenceModel {
  /** @format decimal */
  hours: number;
  id: string;
  description: string;
}

export interface ToolArticleModel {
  articleNumber: string;
  name: string;
}

export interface GetUserSettingResponseModel {
  favoriteExpenseItemIds: string[];
}

export interface UpsertUserSettingModel {
  favoriteExpenseItemIds: string[];
}

export interface WorkDescriptionModel {
  workDescription?: string | null;
}

export interface WorkOrderAppointmentModel {
  /** @format guid */
  id: string;
  /** @format date-time */
  date: string;
  /** @format duration */
  startTime?: string | null;
  /** @format duration */
  endTime?: string | null;
  note?: string | null;
  assignedUsers: UserModel[];
}

export interface WorkOrderModel {
  /** @format guid */
  id: string;
  companyId: string;
  createdBy?: UserModel | null;
  workOrderNumber: string;
  /** @format date-time */
  createdDate: string;
  project: WorkOrderProjectModel;
  object: ObjectModel;
  customer: CustomerModel;
  insuranceClaim: InsuranceClaimModel;
  contacts: ContactModel[];
  finance: FinanceModel;
  order: OrderModel;
  planning: PlanningModel;
  risk: RiskModel;
  meps: MepsModel;
  zendesk?: ZendeskModel | null;
  state: StateModel;
  selfInspection: SelfInspectionModel;
  isOpen: boolean;
}

export interface RiskModel {
  riskAssessment: RiskAssessment;
}

export enum RiskAssessment {
  NotAssessed = 0,
  Assessed = 1,
  NotApplicable = 2,
}

export interface StateModel {
  currentState: WorkOrderState;
}

export enum WorkOrderState {
  Unknown = 0,
  Registered = 1000,
  InProgress = 2000,
  WaitingForVerification = 2500,
  WorkDone = 3000,
  ReadyForInvoicing = 4000,
  Invoiced = 5000,
  Finished = 6000,
  Deleted = 7000,
  Cancelled = 8000,
}

export interface SelfInspectionModel {
  pre: SelfInspectionStageStateModel;
  post: SelfInspectionStageStateModel;
}

export interface SelfInspectionStageStateModel {
  state: SelfInspectionState;
}

export enum SelfInspectionState {
  Missing = 0,
  Started = 1,
  Partial = 2,
  Completed = 3,
  NotApplicable = 4,
}

export interface ExpenseReportModel {
  /** @format guid */
  id: string;
  /** @format date-time */
  date: string;
  createdBy: UserModel;
  /** @format decimal */
  quantity: number;
  article: ExpenseReportArticleModel;
  comment?: string | null;
  isDeletable: boolean;
}

export interface CreateExpenseReportModel {
  /** @format date-time */
  date: string;
  /** @format decimal */
  quantity: number;
  article: ExpenseReportArticleModel;
  comment?: string | null;
}

export interface WorkOrderListResultModel {
  entries: WorkOrderListModel[];
  facets: Facet[];
  /** @format int32 */
  total: number;
}

export interface WorkOrderListModel {
  /** @format guid */
  id: string;
  workOrderNumber: string;
  address?: string | null;
  city?: string | null;
  workType?: string | null;
  foreman?: UserModel | null;
  /** @format date-time */
  createdDate?: string | null;
  hasRiskAssessment: boolean;
  labels: string[];
  /** @format int32 */
  commentCount: number;
  isOpen: boolean;
  currentState: WorkOrderState;
  objectTitle?: string | null;
  /** @format decimal */
  timeReportHours: number;
  /** @format int32 */
  expenseReportCount: number;
  shortDescription?: string | null;
  /** @format int32 */
  appointmentCount: number;
  /** @format date-time */
  plannedStartDate?: string | null;
  /** @format date-time */
  plannedEndDate?: string | null;
  /** @format decimal */
  expectedHoursReported?: number | null;
  /** @format int32 */
  toolRentedCount: number;
  /** @format int32 */
  toolReturnedCount: number;
}

export interface Facet {
  name: string;
  values: FacetValue[];
}

export interface FacetValue {
  value: string;
  text: string;
}

export enum WorkOrderSortFields {
  CreatedDate = 0,
  ObjectAddress1 = 1,
  Foreman = 2,
  WorkOrderNumber = 3,
  CreatedBy = 4,
}

export interface WorkOrderLookupModel {
  /** @format guid */
  id: string;
}

export interface TimeReportModel {
  /** @format guid */
  id: string;
  /** @format date-time */
  date: string;
  user: UserModel;
  createdBy: UserModel;
  /** @format decimal */
  hours: number;
  timeType: TimeTypeModel;
  comment: string;
  isDeletable: boolean;
}

export interface CreateTimeReportModel {
  /** @format date-time */
  date: string;
  /** @format decimal */
  hours: number;
  timeType: TimeTypeModel;
  comment: string;
}

export interface ToolModel {
  /** @format guid */
  id: string;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate?: string | null;
  location?: string | null;
  createdBy: UserModel;
  article: ToolArticleModel;
  isDeletable: boolean;
}

export interface RentToolModel {
  article: ToolArticleModel;
  /** @format date-time */
  startDate: string;
  location?: string | null;
}

export interface ReturnToolModel {
  /** @format date-time */
  endDate: string;
}

export interface WasteModel {
  article: WasteArticleModel;
  /** @format decimal */
  quantity: number;
}

export interface WasteArticleModel {
  articleNumber: string;
  name: string;
  unit: string;
}

export interface WorkOrderSummaryModel {
  /** @format guid */
  id: string;
  company: CompanyModel;
  workOrderNumber: string;
  address?: string | null;
  city?: string | null;
  workType?: string | null;
  foreman?: UserModel | null;
  /** @format date-time */
  createdDate?: string | null;
  currentState: WorkOrderState;
  objectTitle?: string | null;
  shortDescription?: string | null;
}

export interface CompanyModel {
  id: string;
  name: string;
}

export interface ZendeskTicketModel {
  /** @format int32 */
  id: number;
  description: string;
  subject: string;
  availableTransitions: StatusTransition[];
  /** @format int64 */
  assigneeId?: number | null;
  status: TicketStatusModel;
  customerInformation: CustomerInformationModel;
}

export enum StatusTransition {
  Reject = 0,
  Hold = 1,
}

export interface TicketStatusModel {
  /** @format int64 */
  id: number;
  label: string;
  statusCategory: string;
}

export interface CustomerInformationModel {
  name?: string | null;
  phone?: string | null;
  email?: string | null;
  address?: string | null;
  postalCode?: string | null;
  city?: string | null;
}

export interface ZendeskTicketHoldModel {
  comment: string;
  /** @format date-time */
  followUpDate: string;
}

export interface ZendeskTicketRejectModel {
  comment: string;
}
