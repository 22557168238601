import type { LocationQuery } from "vue-router";
import type { ProjectSearchParameters } from "@/modules/projects/queries";
import { parseLocationQueryValueInt, parseStringOrUndefined } from "@/core/router/routeParser";
import { removeNullValues } from "@/helpers/object-helpers";
import { addWeeks, startOfWeek, subWeeks } from "date-fns";

const defaultParameters = {
  searchTerm: undefined,
  showAllProjects: false,
  showClosedProjects: false,
  sortDirection: 1,
  sortField: 0,
  offset: 0,
  limit: 50,
  showSubtasks: false,
} as const satisfies ProjectSearchParameters;

export function makeProjectSearchParametersFromRoute(parameters: LocationQuery): ProjectSearchParameters {
  return {
    ...defaultParameters,
    limit: parseLocationQueryValueInt(parameters.limit, defaultParameters.limit),
    offset: parseLocationQueryValueInt(parameters.offset, defaultParameters.offset),
    sortDirection: parseLocationQueryValueInt(parameters.sortDirection, defaultParameters.sortDirection),
    sortField: parseLocationQueryValueInt(parameters.sortField, defaultParameters.sortField),
    showAllProjects: parameters.showAllProjects === "true",
    showClosedProjects: parameters.showClosedProjects === "true",
    searchTerm: parseStringOrUndefined(parameters.searchTerm),
    showSubtasks: parameters.showSubtasks === "true",
  };
}

export function makeRouterQueryFromProjectSearchParameters(searchObject: ProjectSearchParameters) {
  const queryParameters = {
    searchTerm: searchObject.searchTerm,
    sortDirection: searchObject.sortDirection?.toString(),
    sortField: searchObject.sortField?.toString(),
    limit: searchObject.limit?.toString(),
    offset: searchObject.offset?.toString(),
    showAllProjects: searchObject.showAllProjects?.toString(),
    showClosedProjects: searchObject.showClosedProjects?.toString(),
    showSubtasks: searchObject.showSubtasks?.toString(),
  };
  return removeNullValues(queryParameters);
}

export function chartDefaultDates(now: Date) {
  const startDate = subWeeks(startOfWeek(now), 1);
  const endDate = addWeeks(startDate, 3);

  return { startDate, endDate };
}
