import { defineStore } from "pinia";
import type { CreditCheckModel } from "@/modules/customer/types";

type CreateCustomerStore =
  | CreditCheckModel
  | {
      kind: "empty";
      data: null;
      customerType: null;
    };

export const useCreateCustomerStore = defineStore("createCustomer", {
  state: () => {
    return {
      kind: "empty",
      data: null,
      customerType: null,
    } as CreateCustomerStore;
  },
  actions: {
    setCustomer(customer: CreditCheckModel) {
      this.kind = customer.kind;
      this.data = customer.data;
      this.customerType = customer.customerType;
    },
  },
});
