<script setup lang="ts">
import { computed } from "vue";
import { navLinks, resolveExternalLinks, resolveMyPagesLinks } from "./navLinks";
import IntercomListItem from "./IntercomListItem.vue";
import { useAuthenticatedUserStore } from "@/services/store";
import { useRouter } from "vue-router";
import UserMenu from "@/modules/root/components/UserMenu.vue";
import { useUserMeQuery } from "@/modules/authentication/queries";

defineProps<{ modelValue: boolean }>();
const emit = defineEmits<{ (e: "update:modelValue", value: boolean): void }>();

const store = useAuthenticatedUserStore();
const router = useRouter();
const { data: userMeData } = useUserMeQuery();

const userName = computed<string | null>(() => store.userName);

const visibleNavLink = computed(() => {
  const isForeman = store.isForeman;
  const filteredLinks = navLinks.filter((x) => !x.foremanOnly || isForeman);
  const isNewVersionAvailable = store.application.hasNewVersion;
  return filteredLinks.map((link) => {
    const to = { name: link.to };
    return {
      title: link.title,
      icon: link.icon,
      ...(isNewVersionAvailable ? { to: undefined, href: router.resolve(to).href } : { to, href: undefined }),
      children: link.children,
      hardLink: isNewVersionAvailable,
    };
  });
});

const externalLinks = resolveExternalLinks();

const myPagesLinks = computed(() => {
  const myLinks = resolveMyPagesLinks();
  return myLinks.filter((x) => !x.foremanOnly || store.isForeman);
});

const activeCompany = computed(() => {
  if (userMeData.value == null) {
    return null;
  }
  return userMeData.value.companies.find((company) => company.id === store.activeCompanyId) ?? null;
});
</script>
<template>
  <VNavigationDrawer :model-value="modelValue" @update:model-value="(e) => emit('update:modelValue', e)">
    <VCard class="bg-primary" flat style="border-radius: 0">
      <template v-if="activeCompany" #title> {{ activeCompany.name }} </template>
      <template v-else #title>
        <img src="../../../assets/images/icons/ocab-logo-white.svg" width="40%" alt="" style="border-radius: 4px"
      /></template>
      <template v-if="userName" #subtitle>{{ userName }}</template>
      <template #append>
        <VAvatar>
          <UserMenu />
        </VAvatar>
      </template>
    </VCard>

    <VList nav density="compact">
      <IntercomListItem />
      <VListSubheader> Navigering </VListSubheader>
      <template v-for="(item, i) in visibleNavLink" :key="`${i}-${item.hardLink}`">
        <VListItem link :to="item.to" :href="item.href" :exact="item.to?.name === 'home'">
          <template #prepend>
            <VIcon>{{ item.icon }}</VIcon>
          </template>
          <VListItemTitle>{{ item.title }}</VListItemTitle>
        </VListItem>
        <VListItem v-for="(child, childIndex) in item.children" :key="childIndex" link :to="child.to" exact>
          <template #prepend><VIcon /></template>
          <VListItemTitle class="text-wrap">{{ child.title }}</VListItemTitle>
        </VListItem>
      </template>
      <VDivider />
      <VListSubheader>
        Mina sidor
        <VIcon class="ml-1" size="x-small">mdi-open-in-new</VIcon>
      </VListSubheader>
      <VListItem v-for="link in myPagesLinks" :key="link.href" link :href="link.href" target="_blank">
        <template #prepend>
          <VIcon>{{ link.icon }}</VIcon>
        </template>
        <VListItemTitle>{{ link.title }}</VListItemTitle>
      </VListItem>
      <VDivider />
      <VListSubheader>
        Externa länkar
        <VIcon class="ml-1" size="x-small">mdi-open-in-new</VIcon>
      </VListSubheader>
      <VListItem v-for="link in externalLinks" :key="link.href" link :href="link.href" target="_blank">
        <template #prepend>
          <VIcon>{{ link.icon }}</VIcon>
        </template>
        <VListItemTitle>{{ link.title }}</VListItemTitle>
      </VListItem>
    </VList>
  </VNavigationDrawer>
</template>
