import { defineStore } from "pinia";
import { defaultParameters } from "@/modules/workorder-list/workOrderSearchAdapters";
import type { WorkOrderSearchParameters } from "@/modules/workorder-list/types";

type SearchStore = {
  searchOptions: WorkOrderSearchParameters;
};

export const useWorkOrderSearchStore = defineStore("workOrderSearchStore", {
  state: (): SearchStore => ({
    searchOptions: { ...defaultParameters },
  }),
  actions: {
    saveSearchOptions(searchOptions: WorkOrderSearchParameters) {
      this.searchOptions = searchOptions;
    },
  },
});
