<script setup lang="ts">
import { useBreakpoint } from "@/helpers/useBreakpoint";
import type { RouteLocationRaw } from "vue-router";

defineProps<{
  to?: RouteLocationRaw;
}>();

const breakpoint = useBreakpoint();
</script>

<template>
  <VBtn
    :icon="breakpoint.mobile"
    variant="flat"
    color="primary"
    :size="breakpoint.mobile ? 'small' : 'default'"
    :to="to"
    :class="{ 'ml-4': !breakpoint.mobile }"
    v-bind="$attrs"
  >
    <VIcon size="large" :class="{ 'mr-1': !breakpoint.mobile }">mdi-plus</VIcon>
    <span v-if="!breakpoint.mobile"> Skapa order </span>
  </VBtn>
</template>
