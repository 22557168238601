type Valuable<T> = { [K in keyof T as T[K] extends null | undefined ? never : K]: T[K] };

export function removeNullValues<T extends object, V = Valuable<T>>(obj: T): V {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, v]) => !((typeof v === "string" && !v.length) || v === null || typeof v === "undefined"),
    ),
  ) as V;
}

export function addPrefixToKeys(object: object, prefix: string) {
  return Object.fromEntries(Object.entries(object).map(([key, value]) => [`${prefix}${key}`, value]));
}

export function filterObjectOnKeyContaining(object: object, keyValue: string) {
  return Object.fromEntries(Object.entries(object).filter((entry) => entry[0].startsWith(keyValue)));
}

export function removeEntriesContainingKey(object: object, keyValue: string) {
  return Object.fromEntries(Object.entries(object).filter((entry) => !entry[0].includes(keyValue)));
}

export function removePrefixFromObjectKeys(object: object, prefix: string) {
  return Object.fromEntries(Object.entries(object).map((entry) => [entry[0].substring(prefix.length), entry[1]]));
}
