import { inject, provide, ref } from "vue";
import type { Ref } from "vue";

const APP_SHOW_NAVIGATION_DRAWER = Symbol("VUE_APPLICATION_INSIGHTS");

export function useNavigationDrawer() {
  const showNavigationDrawer = inject<Ref<boolean>>(APP_SHOW_NAVIGATION_DRAWER);

  function toggleNavigationDrawer() {
    if (showNavigationDrawer) {
      showNavigationDrawer.value = !showNavigationDrawer.value;
    }
  }

  return { toggleNavigationDrawer };
}

export function initializeNavigationDrawer(expandedNavigationDrawer: boolean) {
  const showNavigationDrawer = ref(expandedNavigationDrawer);
  provide(APP_SHOW_NAVIGATION_DRAWER, showNavigationDrawer);
  return showNavigationDrawer;
}
