<script setup lang="ts">
import FullScreenBlockingPage from "@/components/BlockingPage.vue";
import { computed, onMounted } from "vue";
import { useAuthenticatedUserStore } from "@/services/store";

const props = defineProps<{ login: boolean }>();

const store = useAuthenticatedUserStore();

const header = computed(() => (props.login ? "Du skickas nu till inloggningen..." : "Du loggas nu ut..."));
const icon = computed(() => (props.login ? "mdi-login" : "mdi-logout"));

onMounted(async () => {
  if (props.login) {
    const href = "/";
    await store.userLogin(href);
  } else {
    await store.userLogout();
  }
});
</script>

<template>
  <FullScreenBlockingPage :header="header" :icon="icon" />
</template>
