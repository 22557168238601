<script setup lang="ts">
import { intercomHide, intercomShow, useIntercomMessageCounter } from "@/services/intercom";
import { ref } from "vue";

const show = ref(false);
const { messageCounter } = useIntercomMessageCounter();

function toggleIntercom() {
  if (show.value) {
    intercomHide();
  } else {
    intercomShow();
  }

  show.value = !show.value;
}
</script>

<template>
  <VListItem link @click="toggleIntercom">
    <template #prepend>
      <VIcon>mdi-message</VIcon>
    </template>
    <template #append>
      <VAvatar color="primary" size="x-small" class="text-caption">
        {{ messageCounter }}
      </VAvatar>
    </template>
    <VListItemTitle>Support</VListItemTitle>
  </VListItem>
</template>
